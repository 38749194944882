<template>
    <div v-if="enabled">
        <Stars 
            class="width-full" 
            :star-class="starClass" 
            :rating="data.average_score" 
        />
        <span v-if="showCount">
            {{ data.count }} 
            {{ $translate('user_reviews', 'recensioner') }}
        </span>
    </div>
</template>

<script>
import Stars from './Stars.vue';
export default {
    components: { Stars },
    props: {
        data: {
            type: Object,
            required: false,
            default: () => {}
        },
        showCount: {
            type: Boolean,
            required: false,
            default: Boolean
        },
        starClass: {
            type: String,
            required: false,
            default: 'fa-xl'
        }
    },
    computed: {
        enabled() {
            return ! this.$isNorway() && this.data;
        }
    }
};
</script>

<style lang="scss" scoped>
    span {
        font-family: $fontText;
        margin-top: 10px;
        display: block;
    }
</style>
