<template>
    <tooltip 
        v-if="model.hasFirstYearOffer && $validateText(tooltipText)"
        class="annual-fee-tooltip" 
        :tooltip-text="tooltipText" 
    />
</template>

<script>
import Tooltip from '@/components/Tooltip.vue';
export default {
    components: {
        Tooltip
    },
    props: {
        model: {
            type: Object,
            required: true,
        }
    },
    computed: {
        tooltipText() {
            return this.$translate('yearly_fee_tooltip');
        }
    }
};
</script>
<style lang="scss" scoped>
.annual-fee-tooltip {
    display: inline !important;
    margin-right: 0;
    color: $font;
}
</style>