<template>
    <Section width="md" class="company-info">
        <div class="table-wrapper">
            <div
                v-for="(item, index) in tablesItems"
                :key="index"
            >
                <h2 class="header">{{ item.title }}</h2>
                <Table 
                    :table-headers="tableHeaders"
                    :table-values="item.data"
                />
            </div>
        </div>
    </Section>
</template>
<script>
import Table from '../Table.vue';
export default {
    components: {
        Table
    },
    props: {
        cards: {
            type: Array,
            required: true
        }
    },
    computed: {
        tableHeaders() {
            return this.cards.map(card => card.data.title);
        },
        companyInfoData() {
            const dataItems = [
                { 
                    key: 'name', 
                    title: this.$translate('company_name', 'Företagsnamn'), 
                },
                { 
                    key: 'phone', 
                    title: this.$translate('phone', 'Telefon')
                },
                { 
                    key: 'email',
                    title: this.$translate('email', 'E-post')
                },
                { 
                    key: 'addres',
                    title: this.$translate('address', 'Adress')
                }
            ];

            return dataItems.map(dataItem => ({
                title: dataItem.title,
                dataItems: this.cards.map(card => {
                    return {
                        value: card.model.company[dataItem.key] || '-'
                    };
                })
            }));
        },
        openingHoursData() {
            const days = [
                { 
                    key: 'monday',
                    title: this.$translate('monday', 'Måndag') 
                },
                { 
                    key: 'tuesday',
                    title: this.$translate('tuesday', 'Tisdag') 
                },
                { 
                    key: 'wednesday',
                    title: this.$translate('wednesday', 'Onsdag')
                },
                { 
                    key: 'thursday',
                    title: this.$translate('thursday', 'Torsdag') 
                },
                { 
                    key: 'friday',
                    title: this.$translate('friday', 'Fredag') 
                },
                { 
                    key: 'saturday',
                    title: this.$translate('saturday', 'Lördag') 
                },
                { 
                    key: 'sunday',
                    title: this.$translate('sunday', 'Söndag') 
                }
            ];
            return days.map(day => ({
                title: day.title,
                dataItems: this.cards.map(card => {
                    return {
                        value: card.model.openingHours[day.key] || this.$translate('closed', 'Stängt')
                    };
                })
            }));
        },
        tablesItems() {
            return [
                {
                    title: this.$translate('company_info', 'Företagsinformation'),
                    data: this.companyInfoData
                },
                {
                    title: this.$translate('opening_hours', 'Öppettider'),
                    data: this.openingHoursData
                }
            ];
        }
    }
};
</script>
<style lang="scss" scoped>
    .company-info::v-deep {
        .table-wrapper {
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
            @include device(pad) {
                gap: 3rem;
            }
        }
    }
</style>