<template>
    <div class="compare-two-cards-table mobile-scroll-shadow">
        <table class="table">
            <thead>
                <tr>
                    <th :class="{'hide-in-mobile': enableMobileLabels}" />
                    <slot name="table-headers">
                        <th v-for="(header, index) in tableHeaders" :key="index">
                            {{ header }}
                        </th>
                    </slot>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(row, rowIndex) in tableValues" :key="rowIndex">
                    <th :class="{'hide-in-mobile': enableMobileLabels}">
                        {{ row.title }}
                    </th>
                    <td v-for="(dataItem, index) in row.dataItems" :key="index">
                        <span v-if="enableMobileLabels" class="mobile-data-title">
                            {{ row.title }}
                        </span>
                        <ul v-if="row.hasList" class="list">
                            <li
                                v-for="(data, dataItemIndex) in dataItem"
                                :key="dataItemIndex"
                            >
                                <i :class="data.icon" />
                                <span>{{ data.title }}</span>
                            </li>
                        </ul>
                        <div v-else>
                            <i 
                                v-if="row.hasIconValue" 
                                class="icon-value" 
                                :class="dataItem.value" 
                            />
                            <span 
                                v-else 
                                :class="{'highlighted': dataItem.isHighlighted}"
                                v-html="dataItem.value" 
                            />
                            <Tooltip 
                                v-if="row.hasTooltip && dataItem.validate"
                                :tooltip-text="dataItem.tooltipText" 
                            />
                        </div>
                    </td>
                </tr>
                <tr v-if="hasOutLinks">
                    <th :class="{'hide-in-mobile': enableMobileLabels}">
                        {{ $translate('ct_to_application_th', 'Ansök') }}
                    </th>
                    <td v-for="document in outLinks" :key="document.id">
                        <div class="out-link-wrapper">
                            <ButtonTarget
                                v-if="document.data.redirect_enabled"
                                :link="document"
                                :text="$translate('ct_to_application', 'Ansök')"
                                icon="fas fa-lock white"
                                background="green"
                                size="md"
                            />
                            <div v-if="$isNorway() && document.data.redirect_enabled" class="text-subtitle">
                                {{ $getApplySecurelyString(document) }}
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import Tooltip from '@/components/Tooltip.vue';
export default {
    components: {
        Tooltip
    },
    props: {
        tableHeaders: {
            type: Array,
            required: false,
            default: Array
        },
        tableValues: {
            type: Array,
            required: true
        },
        outLinks: {
            type: Array,
            required: false,
            default: Array
        },
        enableMobileLabels: {
            type: Boolean, 
            required: false,
            default: false
        }
    },
    computed: {
        hasOutLinks() {
            return this.outLinks
                .some(link => link.data.redirect_enabled);
        }
    }
};
</script>
<style lang="scss" scoped>
    .compare-two-cards-table::v-deep {
        margin-top: 1rem;
        box-shadow: $bxs;
        overflow-x: auto;
        @include border-radius(3px);

        .table {
            width: 100%;
            tr {
                @include border(bottom);
                &:nth-child(even) {
                    background-color: $lightgray;
                }
                td {
                    font-family: $fontText;
                    font-size: 14px;
                    @include border(left);
                    
                    .highlighted {
                        color: $darkgreen;
                        font-weight: bold;
                    }

                    .out-link-wrapper {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        gap: 0.5rem;
                        width: fit-content;
                    }

                    a {
                        display: block;
                        @include border-radius(3px);
                        @include device(pad) {
                            width: fit-content;
                        }
                    }

                    .list {
                        display: flex;
                        flex-direction: column;

                        li {
                            display: flex;
                            align-items: baseline;
                            gap: 0.5rem;
                            padding: 0.25rem 0;
                        }
                    }
                    
                    &:has(.icon-value) {
                        vertical-align: middle;
                    }
                    .cross-mark {
                        color: $red;
                    }
                    .check-mark, .fa-user-check {
                        color: $green;
                    }
                }
                th {
                    font-family: $fontHeader;
                    &:not(:first-child) {
                        @include border(left);
                    }
                    &:first-child {
                        @include device(pad) {
                            width: fit-content;
                        }
                        @include device(mobile) {
                            min-width: 90px;
                        }
                    }
                    img {
                        width: 120px;
                        @include device(pad) {
                            width: 150px;
                        }
                        @include device(desktop) {
                            width: unset;
                        }
                    }
                }
                td, th {
                    text-align: left;
                    padding: 0.75rem;
                    vertical-align: top;
                    min-width: 150px;

                    @include device(pad) {
                        padding: 1rem;
                        width: 50%;
                    }
                    span {
                        display: inline-block;
                        &:first-letter {
                            text-transform: uppercase;
                        }
                        line-height: 20px;
                    } 
                }
                .hide-in-mobile {
                    @include device(mobile) {
                        display: none;
                    }
                }
                .mobile-data-title {
                    display: none;
                    @include device(mobile) {
                        display: block;
                        font-family: $fontHeader;
                        font-size: 14px;
                        margin-bottom: 0.5rem;
                    }
                }
            }
        }
    }
</style>