<template>
    <Section
        width="sm"
        class="banner post-banner"
    >
        <HeaderBanner
            :header="$prismic.asText(document.data.title)"
            :teaser="document.data.preamble"
            align="left"
            class="banner-image-header"
        />
        <PrismicImage
            :img="document.data.image"
            class="image"
            w="700"
        />
        <div v-if="document.data.author?.data" class="preamble-info">
            <nuxt-link 
                :to="$prismic.linkResolver(document.data.author)" 
                class="author-wrapper"
            >
                <i class="fa-solid fa-pen-to-square icon" />
                <span>
                    {{ $translate('written_by', 'Skriven av') }}
                </span>
                <span class="author-name small">
                    {{ document.data.author.data.name }}
                </span>
            </nuxt-link>
            <div>
                <i class="fa-solid fa-calendar-lines-pen icon" />
                <span v-html="lastPublicationDate" />
            </div>
            <read-time 
                :slices="document.data.body"
                :enable-read-time-text="true"
                icon="fas fa-clock" 
            />
        </div>
    </Section>
</template>

<script>
import ReadTime from '@/components/ReadTime.vue';
export default {
    components: { 
        ReadTime 
    },
    props: {
        document: {
            type: Object,
            required: true
        }
    },
    computed: {
        lastPublicationDate() {
            return this.$translate('authority_box_last_publication_date', 'Senast uppdaterad {date}')
                .replace('{date}', `<span class="small">${this.document.last_publication_date.split('T')[0]} </span>`);
        }
    }
};
</script>

<style lang="scss" scoped>
    .banner.post-banner::v-deep {
            padding-bottom: 0;
            .banner-image-header.left {
                @include device(pad) {
                    text-align: center;
                    justify-content: center;
                }

                .banner-header__teaser {
                    margin-bottom: 1rem;
                }
            }
            .image {
                max-width: 100%;
                box-shadow: $bxs;
                @include border-radius(5px);
            }

            .preamble-info {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 0.75rem;

                margin-top: 1rem;
                padding-bottom: 1rem;
                font-weight: bold;
                font-family: $fontText;
                font-size: 13px;
                color: #677788;
                @include border(bottom);
                @include device(pad) {
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                }
                .author-wrapper {
                    display: flex;
                    align-items: baseline;
                    gap: 0.25rem;
                    color: #677788;
                    &:hover {
                        .author-name {
                            color: $pink;
                            text-decoration: none;
                        }
                    }
                    .author-name {
                        text-decoration: underline;
                    }
                }
                .icon {
                    color: $pink;
                }
                .read-time {
                    i {
                        color: $pink;
                    }
                    .read-time-minutes {
                        font-size: 14px;
                        font-weight: normal;
                    }
                }
                .small {
                    font-size: 14px;
                    font-weight: normal;
                }
            }
        }
</style>