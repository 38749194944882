<template>
    <Section
        class="banner"
        background="white"
    >
        <div class="container">
            <transition name="slide-right" appear>
                <div class="container-inner">
                    <PrismicImage
                        :img="document.data.card_image"
                        w="300"
                        h="190"
                        :lazy="false"
                    />

                    <template v-if="document.data.redirect_enabled">
                        <CardStarsWithCount
                            v-if="model._ratings"
                            v-scroll-to="'#userreview'"
                            :data="model._ratings"
                        />

                        <div class="container-inner__buttons">
                            <ButtonTarget
                                size="md"
                                background="green"
                                class="flex"
                                :text="$translate('cta_credit_card_page', 'Apply')"
                                :link="document"
                                placement="banner"
                                icon="fas fa-lock white"
                            />

                            <span>{{ $getApplySecurelyString(document) }}</span>
                        </div>
                    </template>

                    <!-- Narrow in germany because the words are so long
                            ugly fix but couldn't find a better way -->
                    <ListCardInfo :data="document" :model="model" :class="$isGermany() ? 'narrow' : ''" />
                    <span v-if="model.complianceExample" class="text-compliance">
                        {{ model.complianceExample }}
                    </span>
                </div>
            </transition>
        </div>

        <div class="container">
            <div class="container-inner">
                <HeaderBanner
                    :header="document.data.title"
                    align="left"
                />

                <ul
                    v-if="document.data.redirect_enabled"
                    class="no-style list-icon"
                >
                    <li
                        v-for="(iterator, index) in document.data.pros"
                        :key="index"
                        class="list-icon list-check"
                        v-html="$removeParagraphs($prismic.asHtml(iterator.pro))"
                    />
                </ul>

                <div
                    v-else-if="document.data.archived"
                    class="container-inner__card-text"
                >
                    <span>{{ cardArchivedText }}</span>
                </div>

                <div
                    v-else
                    class="container-inner__card-text"
                >
                    <span>{{ cardAlternativeText }}</span>
                </div>

                <transition name="slide-left" appear>
                    <div
                        v-if="$prismic.asText(document.data.signup_offer).length && document.data.redirect_enabled"
                        class="offer"
                    >
                        <div class="offer-inner">
                            <img
                                src="@/assets/icons/pink/icon-bonus.png"
                                :alt="$prismic.asText(document.data.signup_offer)"
                            >
                            <h2>{{ $translate('offer', 'Erbjudande') }}</h2>
                        </div>

                        <span>{{ $prismic.asText(document.data.signup_offer) }}</span>
                    </div>

                    <CardAlternative
                        v-else-if="!document.data.redirect_enabled"
                        :id="document.data.card_alternative.id"
                    />
                </transition>
            </div>
            <credit-warning v-if="showCreditWarning" class="credit-warning" />
        </div>
    </Section>
</template>

<script>
import CardStarsWithCount from '@/components/stars/StarsWithCount.vue';
import CardAlternative from '@/components/cards/CardAlternative.vue';
import ListCardInfo from '@/components/slices/lists/ListCardInfo.vue';
import CreditWarning from '@/components/CreditWarning.vue';
export default {
    components: { CardAlternative, CardStarsWithCount, ListCardInfo, CreditWarning },
    props: {
        document: {
            type: Object,
            required: true
        },
        model: {
            type: Object, 
            required: true
        }
    },
    computed: {
        cardAlternativeText() {
            let string = this.$translate('card_alternative_text', 'Vi samarbetar inte med {card} just nu och rekommenderar istället ett liknande kort.');
            return string.replace('{card}', this.document.data.title);
        },
        cardArchivedText() {
            let string = this.$translate('card_archived_text', '{card} är tyvärr inte tillgängligt längre. Vi har tagit fram ett liknande kort som du kanske är intresserad av.');
            return string.replace('{card}', this.document.data.title);
        },
        showCreditWarning() {
            return this.$isSweden() && this.model.isCredit;
        },
    }
};
</script>

<style lang="scss" scoped>
    .banner::v-deep {
        padding: 0;
        @include device(pad) {
            margin-bottom: 48px;
        }
        .section-inner {
            width: 100%;
            @include flex(center, stretch);

            .container {
                width: 100%;
                padding: 40px 12px;
                box-sizing: border-box;

                @include device(pad) {
                    padding: 40px;
                }

                @include device(desktop) {
                    padding-top: 80px;
                    padding-bottom: 80px;
                    flex: 1;
                    width: auto;
                }

                &-inner {
                    max-width: 500px;
                    width: 100%;

                    &__card-text {
                        @include spacing(padding, 5);
                        background: $lightgray;
                        font-family: $fontText;
                        @include font-size(16px, 10px);
                        @include spacing(margin, 6, bottom);
                    }
                }

                &:first-child {
                    background: $lightblue;
                    @include flex;

                    @include device(desktop) {
                        padding-left: 20px;
                        padding-right: 40px;
                        @include flex(end, start);
                    }

                    .container-inner {
                        box-shadow: $bxs;
                        background: $white;
                        @include spacing(padding, 5);
                        @include grid(1, 20px);
                        text-align: center;

                        img {
                            margin: auto;
                            height: auto;
                        }

                        &__buttons {
                            @include grid(1, 10px);

                            span {
                                font-family: $fontText;
                                opacity: .6;
                                @include font-size(12px);
                            }
                        }
                    }
                }

                &:last-child {
                    @include flex;

                    @include device(desktop) {
                        display: block;
                        padding-left: 40px;
                        padding-right: 20px;
                    }

                    ul {
                        @include grid(1, 20px);
                        padding: 0;

                        li {
                            font-family: $fontText;
                            @include font-size(18px);
                        }
                    }

                    .offer {
                        background: $lightblue;
                        box-shadow: $bxs;
                        @include spacing(padding, 5);
                        @include spacing(margin, 8, top);

                        &-inner {
                            @include flex(start);

                            img {
                                @include size(60px);
                                margin-right: 20px;
                            }
                        }

                        span {
                            font-family: $fontText;
                            @include font-size(16px, 10px);
                            margin-top: 20px;
                            display: block;
                        }
                    }
                }
                .credit-warning {
                    margin-top: 20px;
                    max-width: 500px;
                }
            }
        }
    }
</style>
