export default {
    computed: {
        fees() {
            return [
                {
                    title: this.$translate('withdrawal_fee', 'Uttagsavgift'), 
                    data: this.cards.map(card => {
                        return card.model.getWithdrawalFeeString(
                            this.$translate('bd_fee_percentage_and_min', '{percentage} (min {amount})'),
                            this.$translate('bd_fee_percentage_and_fixed', '{percentage} + {amount}'));
                    }),
                },
                {
                    title: this.$translate('currency_exchange_fee', 'Valutapåslag i utlandet'),
                    data: this.cards.map(card => card.model.getCurrencyExchangeFeeString()),
                    key: 'currencyExchangeFeePercentage',
                },
                {
                    title: this.$translate('ct_avi_fee', 'Aviavgift'),
                    data: this.cards.map(card => {
                        return card.model.getAviFeeString(
                            this.$translate('bd_avi_fee', '{paperInvoiceFee} ({eInvoiceFee} e-faktura)')
                        );
                    }),
                    key: 'aviFeeEInvoice',
                    disabled: this.$isGermany()
                },
                {
                    title: this.$translate('ct_reminder_fee', 'Påminnelseavgift'),
                    data: this.cards.map(card => card.model.reminderFeeString),
                    key: 'reminderFee',
                    disabled: this.$isGermany()
                },
                {
                    title: this.$translate('ct_late_payment_fee', 'Förseningsavgift'),
                    data: this.cards.map(card => card.model.latePaymentFeeString),
                    key: 'latePaymentFee',
                    disabled: this.$isGermany()
                },
                {
                    title: this.$translate('ct_overdraft_fee', 'Övertrasseringsavgift'),
                    data: this.cards.map(card => card.model.overdraftFeeString),
                    key: 'overdraftFee',
                    disabled: this.$isGermany()
                },
                {
                    title: this.$translate('ct_penalty_interest', 'Dröjsmålsränta'),
                    data: this.cards.map(card => card.model.penaltyInterestString),
                    key: 'penaltyInterest',
                    disabled: this.$isGermany()
                },

            ].filter(fee => !fee.disabled);  
        },
    },
};