<template>
    <Section width="md">
        <div
            v-if="$validateText(headerText)"
            class="rich-text header" 
            v-html="$prismic.asHtml(headerText)"
        />
        <Table
            :table-headers="tableHeaders"
            :table-values="tableValues"
        />
    </Section>
</template>
<script>
import Table from './Table.vue';
import BenefitsLogic from './mixins/benefits-mixins';
export default {
    components: {
        Table
    },
    mixins: [BenefitsLogic],
    props: {
        text: {
            type: Array,
            required: false,
            default: Array
        },
        cards: {
            type: Array,
            required: true
        }
    },
    computed: {
        headerText() {
            return this.$validateText(this.text) 
                ? this.text 
                : this.$translate('compare_two_cards_benefits_text');
        }
    }
};
</script>