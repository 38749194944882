export default {
    computed: {
        tableHeaders() {
            return this.cards.map(card => card.data.title);
        },
        benefitsData() {
            return [
                { 
                    key: 'hasBonusProgram', 
                    title: this.$translate('filter_bonus_program', 'Bonusprogram'), 
                },
                { 
                    key: 'hasDiscountProgram', 
                    title: this.$translate('discount_program', 'Rabattprogram')
                },
                { 
                    key: 'hasCashback',
                    title: this.$translate('filter_cashback', 'Cashback')
                },
                { 
                    key: 'hasLoungeAccess',
                    title: this.$translate('filter_lounge_access', 'Loungetillgång')
                },
                { 
                    key: 'hasConciergeService',
                    title: this.$translate('concierge_service', 'Concierge tjänst')
                },
                { 
                    key: 'hasFuelOffers',
                    title: this.$translate('filter_fuel_offers', 'Drivmedelsrabatter')
                }
            ];
        },
        tableValues() {
            return this.benefitsData.map(dataItem => ({
                title: dataItem.title,
                dataItems: this.cards.map(card => {
                    return {
                        value: 'fa-lg ' + this.$formatBooleanIcon(card.model[dataItem.key]) 
                    };
                }), 
                hasIconValue: true
            }));
        }
    }
};