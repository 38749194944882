<template>
    <Section class="banner" width="lg">
        <div class="container">
            <HeaderBanner
                :header="$prismic.asText(slice.primary.banner_h1)"
                :teaser="slice.primary.banner_teaser"
                align="left"
            />
            <div v-if="dropdowns.length" class="dropdown-wrapper">
                <div
                    v-for="(dropdown, index) in dropdowns" 
                    :key="index"
                >
                    <Expander :title="dropdown.title">
                        <div 
                            class="rich-text" 
                            v-html="$prismic.asHtml(dropdown.content)" 
                        />
                    </Expander>   
                </div>
            </div>
        </div>

        <div v-if="cards.length" class="container">
            <transition name="slide-left" appear>
                <div class="cards-wrapper">
                    <BannerCard 
                        v-for="(card, index) in cards" 
                        :key="index"
                        :card="card"
                    />
                </div>
            </transition>
        </div>
    </Section>
</template>
<script>
import Expander from '@/components/Expander.vue';
import BannerCard from './BannerCard.vue';
export default {
    components: { 
        Expander,
        BannerCard
    },
    props: {
        slice: {
            type: Object,
            required: true
        },
        cards: {
            type: Array,
            required: true
        }
    },
    computed: {
        dropdowns() {
            const dropdowns = [];
            for (let i = 1; i <= 3; i += 1) {
                dropdowns.push({
                    title: this.slice.primary[`banner_dropdown_${i}_header`],
                    content: this.slice.primary[`banner_dropdown_${i}_content`]
                });
            }
            return dropdowns
                .filter(dropdown => this.$validateText(dropdown.title));
        }
    }
};
</script>
<style lang="scss" scoped>
    .banner::v-deep {
        background-image: linear-gradient(284deg, transparent, rgba(20, 106, 255, .05));
        margin-bottom: 48px;
        .section-inner {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            gap: 3rem;
            @include device(pad) {
                grid-template-columns: repeat(2, 1fr);
            }

            .container {
                display: flex;
                flex-direction: column;
                gap: 2rem;

                .dropdown-wrapper {
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;

                    .expander {
                        background-color: $white;
                        box-shadow: $bxs;
                        @include border-radius(3px);
                        &:has(.expander-content-wrapper.expanded) {
                            padding-bottom: 1rem;
                        }
                        .expander-button {
                            display: flex;
                            justify-content: space-between;
                            align-items: baseline;
                            gap: 0.75rem;
                            padding: 1rem;
                            font-weight: bold;
                            font-family: $fontHeader;
                            .expander-title {
                                line-height: 26px;
                            }

                            &.expanded {
                                @include border(bottom);
                            }
                        }
                        .expander-content-wrapper {
                            font-family: $fontText;
                            margin-top: 0;
                            padding: 0 1rem;
                        }
                    }
                }

                .cards-wrapper {
                    display: flex;
                    flex-direction: column;
                    gap: 2rem;
                    font-family: $fontText;

                    .card {
                        display: flex;
                        justify-content: center;
                        flex-wrap: wrap;
                        gap: 1.25rem;
                        padding: 1.25rem;
                        @include border-radius(3px);
                        box-shadow: $bxs;
                        background-color: $lightblue;

                        .card-image-rating {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            gap: 0.5rem;
                            flex: 1 0 0;

                            .card-link {
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                gap: 0.75rem;
                                &:hover {
                                    .card-name {
                                        color: $pink;
                                    }
                                }
                                @include device(pad) {
                                    gap: .5rem;
                                }
                                img {
                                    max-width: unset;
                                }
                                .card-name {
                                    font-family: $fontHeader;
                                    font-size: 18px;
                                    color: $font;
                                    text-align: center;
                                    @include device(pad)  {
                                        margin-top: 0.375rem;
                                    }
                                }
                            }

                            .card-rating {
                                text-align: center;
                            }
                        }
                        
                        .content-wrapper {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            gap: 1rem;
                            flex-grow: 2;
                            min-width: 200px;

                            .card-info {
                                display: flex;
                                flex-direction: column;
                                gap: 0.125rem;
    
                                .card-info-data {
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    gap: 1rem;
                                    background-color: $white;
                                    padding: 0.5rem;

                                    .label {
                                        font-size: 14px;
                                        font-weight: bold;
                                    }
                                    .value {
                                        font-size: 14px;
                                    }
                                }
                            }
                            .card-out-link-wrapper {
                                display: flex;
                                flex-direction: column;
                                gap: 0.5rem;
                                align-items: center;
                                justify-content: center;
                                .card-out-link {
                                    width: 100%;
                                    @include border-radius(3px);
                                }
                            }
                        }
                    }
                }
                .card::wrap {
                    .card-image-rating {
                        flex-basis: 100%;
                    }
                }
            }
        }
    }
</style>