<template>
    <li>
        <nuxt-link
            :to="$prismic.linkResolver(post)"
            :class="post.data.post_category"
            class="news-post"
        >
            <PrismicImage
                :img="post.data.image.teaser"
                :alt="$prismic.asText(post.data.title)"
            />

            <div class="news-post__content">
                <span class="content-header">{{ $prismic.asText(post.data.title) }}</span>
                <p>{{ $prismic.asText(post.data.preamble).substr(0, 150).trim() }}...</p>
                <span>{{ post.first_publication_date.split('T')[0] }}</span>
            </div>
        </nuxt-link>
    </li>
</template>

<script>
export default {
    props: {
        post: {
            type: Object,
            required: true,
            default: () => {}
        }
    }
};
</script>

<style lang="scss" scoped>
    .news-post {
        height: 100%;
        @include flex(center,start);
        color: $font;
        font-family: $fontText;
        box-shadow: $bxs;
        @include border;
        @include border-radius(5px);
        transition: .15s ease-in-out;
        overflow: hidden;

        &:hover { box-shadow: none; }

        img {
            width: 100%;
            height: auto;
        }

        &__content {
            @include grid(1, 20px);
            @include spacing(padding, 5);
            @include border(top, 0);

            .content-header {
                font-family: $fontHeader;
                @include font-size(28px);
            }
        }
    }
</style>
