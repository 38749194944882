<template>
    <Section
        width="md"
        :label="slice.slice_label"
        class="tip-box"
    >
        <div class="container" :class="background">
            <div class="container-inner rich-text">
                <CardImage
                    v-if="slice.primary.link.id"
                    :id="slice.primary.link.id"
                    w="200"
                    class="card"
                />

                <PrismicImage
                    v-else
                    :img="slice.primary.image"
                    w="200"
                    :class="{'iconImage': iconImageStyle}"
                />
                <div class="tip-box-header" v-html="$prismic.asHtml(slice.primary.header)" />
                <div class="tip-box-text rich-text" v-html="$prismic.asHtml(slice.primary.text)" />
            </div>
        </div>
    </Section>
</template>

<script>
import CardImage from '@/components/cards/CardImage.vue';
export default {
    components: { CardImage },
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    computed: {
        iconImageStyle() {
            return this.slice.primary.image.dimensions?.width === this.slice.primary.image.dimensions?.height;
        },
        background() {
            if (this.slice.primary.background === 'light-pink') {
                return 'bg-lpink';
            } 
            else if (this.slice.primary.background === 'light-blue') {
                return 'bg-lblue';
            } 
            else if (this.slice.primary.background === 'white') {
                return 'bg-white';
            } 
            else if (this.slice.primary.background === 'light-gray') {
                return 'bg-lgray';
            } 
            return 'bg-lgreen';
        }
    }
};
</script>

<style lang="scss" scoped>
    .tip-box::v-deep {
        .container.bg-lgray {
            background-color: $lightgray;
        }
        .container.bg-lpink {
            background-color: $lightpink;
        }
        .container.bg-lblue {
            background-color: $lightblue;
        }
        .container.bg-lgreen {
            background-color: $lightgreen;
        }
        .container.bg-white {
            background-color: $white;
        }
        .container {
            @include spacing(padding, 5);
            width: 100%;
            box-shadow: $bxs;
            @include border-radius(5px);
            &-inner {
                display: grid;
                align-items: center;
                grid-template-columns: repeat(12, 1fr);
                grid-template-rows: auto;
    
                @include device(mobile) {
                    row-gap: 25px;
                }
    
                .iconImage {
                    @include device(mobile) {
                        width: 50px;
                        height: 50px;
                    }
                }
                
                .card {
                    img {
                        width: 200px;
                        max-width: 100%;
                    }
                }
    
                img, .card {
                    max-width: 100%;
                    grid-column: 1 / 13;
                    grid-row: 1 / 2;
                    @include device(pad) {
                        grid-column: 1 / 4;
                        grid-row: 1 / 3;
                    }
                }
                
                .tip-box-header, .tip-box-text {
                    @include device(pad) {
                        grid-column: 4 / 13;
                    }
                }
                
                .tip-box-header {
                    @include device(mobile) {
                        grid-column: 1 / 13;
                        grid-row: 2 / 3;
                    }
                    & * {
                        color: $pink;
                        @include device(mobile) {
                            margin-bottom: 0;
                        }
                    }
                }
                
                .tip-box-text {
                    align-self: start;
                    @include device(mobile) {
                        grid-column: 1 / 13;
                        grid-row: 3 / 4;
                    }
                }
    
                .tip-box-text p:first-child {
                    margin-top: 0;
                }
            }        
        }
    }
</style>
