<template>
    <Section v-if="items.length" width="md" class="compiled-data">
        <div 
            v-if="$validateText(headerText)"
            class="rich-text header" 
            v-html="$prismic.asHtml(headerText)"    
        />
        <ul class="compiled-data-list">
            <li 
                v-for="(item, index) in items" 
                :key="index"
                class="compiled-data-item"
            >
                <p class="rich-text" v-html="item" />
            </li>
        </ul>
    </Section>
</template>

<script>
import BenefitsLogic from './mixins/benefits-mixins.js';
import InsurancesLogic from './mixins/insurances-mixins.js';
import { Format } from '@swegaming-ab/nuxtjs-helpers';
export default {
    mixins: [BenefitsLogic, InsurancesLogic],
    props: {
        text: {
            type: Array,
            required: false,
            default: Array
        },
        cards: {
            type: Array,
            required: true
        }
    },
    computed: {
        headerText() {
            return this.$validateText(this.text) 
                ? this.text 
                : this.$translate('compare_two_cards_compiled_data_text');
        },
        generalBenefitsConfig() {
            return {
                getTranslation: () => {
                    return this.$translate(
                        'compiled_data_benefits',
                        '{winner} erbjuder {differences} som förmåner vilket {loser} inte gör'
                    );
                },
                getShortCodes: (winner, loser) => [
                    { 
                        shortCode: '{winner}',
                        value: winner.data.title 
                    },
                    { 
                        shortCode: '{differences}', 
                        value: `<span class="value">${this.getDifferences(winner, loser, this.benefitsData).join(', ')}</span>` 
                    },
                    { 
                        shortCode: '{loser}', 
                        value: loser.data.title 
                    }
                ]
            };
        },
        generalInsurancesConfig() {
            return {
                getTranslation: () => {
                    return this.$translate(
                        'compiled_data_insurances',
                        '{winner} erbjuder {differences} som försäkringar vilket {loser} inte gör'
                    );
                },
                getShortCodes: (winner, loser) => [
                    { 
                        shortCode: '{winner}', 
                        value: winner.data.title 
                    },
                    { 
                        shortCode: '{differences}', 
                        value: `<span class="value">${this.getDifferences(winner, loser, this.insurancesData).join(', ')}</span>` 
                    },
                    { 
                        shortCode: '{loser}', 
                        value: loser.data.title 
                    }
                ]
            };
        },
        config() {
            return {
                benefitsDifferencesCard1: {
                    ...this.generalBenefitsConfig,
                    getWinnerAndLoser: (card1, card2) => this.getDifferences(card1, card2, this.benefitsData).length > 0
                        ? [card1, card2]
                        : null
                },
                benefitsDifferencesCard2: {
                    ...this.generalBenefitsConfig,
                    getWinnerAndLoser: (card1, card2) => this.getDifferences(card2, card1, this.benefitsData).length > 0
                        ? [card2, card1]
                        : null
                },
                insurancesDifferencesCard1: {
                    ...this.generalInsurancesConfig,
                    getWinnerAndLoser: (card1, card2) => this.getDifferences(card1, card2, this.insurancesData).length > 0
                        ? [card1, card2]
                        : null
                },
                insurancesDifferencesCard2: {
                    ...this.generalInsurancesConfig,
                    getWinnerAndLoser: (card1, card2) => this.getDifferences(card2, card1, this.insurancesData).length > 0
                        ? [card2, card1]
                        : null
                },
                minAge: {
                    getWinnerAndLoser: (card1, card2) => {
                        if (card1.model.minAge === card2.model.minAge) {
                            return null;
                        }
                        return card1.model.minAge < card2.model.minAge 
                            ? [card1, card2]
                            : [card2, card1];
                    },
                    getTranslation: (card1, card2) => {
                        if (!card1.model.minAge && card2.model.minAge || card1.model.minAge && !card2.model.minAge) {
                            return this.$translate(
                                'compiled_data_demands_no_min_age',
                                '{winner} har ingen åldersgräns men det har {loser} {loserAge}'
                            );
                        }
                        if (card1.model.minAge > card2.model.minAge || card1.model.minAge < card2.model.minAge) {
                            return this.$translate(
                                'compiled_data_demands_min_age',
                                '{winner} har lägre åldersgräns på {winnerAge} jämfört med {loser} på {loserAge}'
                            );
                        }
                    },
                    getShortCodes: (winner, loser) => [
                        {
                            shortCode: '{winner}',
                            value: winner.data.title
                        },
                        {
                            shortCode: '{winnerAge}',
                            value: `<span class="value minAge">${winner.model.minAge} ${this.$translate('year_short', 'år')}</span>`
                        },
                        {
                            shortCode: '{loser}',
                            value: loser.data.title
                        },
                        {
                            shortCode: '{loserAge}',
                            value: `<span class="value minAge">${loser.model.minAge} ${this.$translate('year_short', 'år')}</span>`
                        }
                    ]
                },
                minimumYearlyIncome: {
                    getWinnerAndLoser: (card1, card2) => {
                        if (
                            card1.model.minimumYearlyIncome === card2.model.minimumYearlyIncome 
                            && !card1.model.acceptsNoIncome === !card2.model.acceptsNoIncome
                        ) {
                            return null;
                        }
                        return card1.model.minimumYearlyIncome < card2.model.minimumYearlyIncome
                            ? [card1, card2]
                            : [card2, card1];
                    },
                    getTranslation: (card1, card2) => {
                        if (
                            (card1.model.minimumYearlyIncome && card2.model.minimumYearlyIncome) && card1.model.minimumYearlyIncome > card2.model.minimumYearlyIncome 
                            || (card1.model.minimumYearlyIncome && card2.model.minimumYearlyIncome) && card1.model.minimumYearlyIncome < card2.model.minimumYearlyIncome
                        ) {
                            return this.$translate(
                                'compiled_data_demands_min_yearly_income',
                                '{winner} har lägre inkomst krav på {winnerIncome} jämfört med {loser} på {loserIncome}'
                            );
                        }
                        return this.$translate(
                            'compiled_data_demands_no_employment',
                            '{winner} har inget krav på inkomst jämfört med {loser} som har en minsta årsinkomst på {loserIncome}'
                        );
                    },
                    getShortCodes: (winner, loser) => [
                        {
                            shortCode: '{winner}',
                            value: winner.data.title
                        },
                        {
                            shortCode: '{winnerIncome}',
                            value: `<span class="value">${Format.currency(winner.model.minimumYearlyIncome)}</span>`
                        },
                        {
                            shortCode: '{loser}',
                            value: loser.data.title
                        },
                        {
                            shortCode: '{loserIncome}',
                            value: `<span class="value">${Format.currency(loser.model.minimumYearlyIncome)}</span>`
                        }
                    ]
                },
                acceptsPaymentRemarks: {
                    getWinnerAndLoser: (card1, card2) => {
                        if (
                            !(this.$isSweden() || this.$isNorway()) 
                            || card1.model.acceptsPaymentRemarks === card2.model.acceptsPaymentRemarks 
                            || !card1.model.acceptsPaymentRemarks === !card2.model.acceptsPaymentRemarks
                        ) {
                            return null;
                        }
                        return card1.model.acceptsPaymentRemarks && !card2.model.acceptsPaymentRemarks
                            ? [card1, card2]
                            : [card2, card1];
                    },
                    getTranslation: () => {
                        return this.$translate(
                            'compiled_data_demands_no_payment_remarks',
                            '{winner} tillåter att du har betalningsanmärkningar men det accepterar inte {loser}'
                        );
                    },
                    getShortCodes: (winner, loser) => [
                        {
                            shortCode: '{winner}',
                            value: winner.data.title
                        },
                        {
                            shortCode: '{loser}',
                            value: loser.data.title
                        }
                    ]
                },
                isCredit: {
                    getWinnerAndLoser: (card1, card2) => {
                        if (
                            !this.$isSweden() 
                            || card1.model.isCredit && card2.model.isCredit 
                            || !card1.model.isCredit && !card2.model.isCredit
                        ) {
                            return null;
                        }
                        return !card1.model.isCredit && card2.model.isCredit
                            ? [card1, card2]
                            : [card2, card1];
                    },
                    getTranslation: () => {
                        return this.$translate(
                            'compiled_data_demands_is_credit',
                            '{winner} tillåter skulder hos kronofogden men det accepterar inte {loser}'
                        );
                    },
                    getShortCodes: (winner, loser) => [
                        {
                            shortCode: '{winner}',
                            value: winner.data.title
                        },
                        {
                            shortCode: '{loser}',
                            value: loser.data.title
                        }
                    ]
                },
            };
        },
        items() {
            return Object.values(this.config)
                .map(({ getWinnerAndLoser, getTranslation, getShortCodes }) => {
                    const winnerAndLoser = getWinnerAndLoser(this.cards[0], this.cards[1]);

                    if (!winnerAndLoser) {
                        return null;
                    }

                    const [winner, loser] = winnerAndLoser;
                    let translation = getTranslation(winner, loser);
                    const shortCodes = getShortCodes(winner, loser);

                    shortCodes.forEach(({ shortCode, value }) => {
                        translation = translation?.replace(shortCode, value);
                    });

                    return translation;
                })
                .filter(Boolean);
        }
    },
    methods: {
        getDifferences(card1, card2, data) {
            return data
                .filter(item => card1.model[item.key] && !card2.model[item.key])
                .map(item => item.title);
        }
    }
};
</script>
<style lang="scss" scoped>
    .compiled-data::v-deep {

        .compiled-data-list {
            display: flex;
            flex-direction: column;
            gap: 0.75rem;
            
            .compiled-data-item {
                display: flex;
                box-shadow: $bxs;
                padding: 1rem;
                font-family: $fontText;
                @include border-radius(3px);
                .minAge {
                    text-transform: lowercase;
                }
                .value {
                    font-weight: bold;

                    i {
                        color: $pink;
                    }
                }
            }
        }
    }   
</style>