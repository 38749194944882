export default {
    computed: {
        lowestValues() {
            return Object.fromEntries(
                Object.entries(this.getHighestOrLowestValues)
                    .filter(([key]) => this.lowestKeys.includes(key))
                    .map(([key, values]) => [key, Math.min(...values)]));
        },
        highestValues() {
            return Object.fromEntries(
                Object.entries(this.getHighestOrLowestValues)
                    .filter(([key]) => this.highestKeys.includes(key))
                    .map(([key, values]) => [key, Math.max(...values)]));
        },
        getHighestOrLowestValues() {
            const keys = [
                ...this.lowestKeys,
                ...this.highestKeys
            ];
            let cardCategoryValues = [];
            let values = {};
            keys.forEach(key => {
                this.cards.forEach(card => {
                    if (key === 'yearlyCost' && card.model.hasFirstYearOffer) {
                        cardCategoryValues.push(card.model.firstYearCost);
                    }
                    else if (key === 'minEffectiveInterestPossible' && card.model.isDebit) {
                        return false;
                    } 
                    return cardCategoryValues.push(card.model[key]); 
                });
                values[key] = cardCategoryValues;
                cardCategoryValues = [];
            });
            return values;
        }
    },
    methods: {
        checkLowest (key, card) {
            if (key === 'yearlyCost' && card.model.hasFirstYearOffer) {
                return card.model.firstYearCost <= this.lowestValues[key];
            }
            if (key === 'minAmountToPay') {
                return card.model.invoiceMinToPayPercentage === this.lowestValues.invoiceMinToPayPercentage && card.model.invoiceMinToPaySum <= this.lowestValues.invoiceMinToPaySum;
            }
            if (key === 'minAviFee') {
                return card.model.aviFeeEInvoice === this.lowestValues.aviFeeEInvoice && card.model.aviFeePaperInvoice <= this.lowestValues.aviFeePaperInvoice;
            }
            return card.model[key] <= this.lowestValues[key];
        },
        checkHighest (key, card) {
            return card.model[key] >= this.highestValues[key];
        },
    }
};