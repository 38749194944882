<template>
    <Section v-if="config.length > 0" class="contact-info-section">
        <h2>{{ $translate('contact_information', 'Kontaktinformation') }}</h2>
        <ul>
            <li v-for="(item, index) in config" :key="index">
                <p class="label">{{ item.label }}</p>
                <p class="value">{{ item.value }}</p>
            </li>
        </ul>
    </Section>
</template>

<script>
export default {
    props: {
        model: {
            type: Object,
            required: true
        }
    },
    computed: {
        contactInfo() {
            return this.model.company;
        },
        config() {
            return [
                {label: this.$translate('company_name', 'Företagsnamn'), value: this.contactInfo.name},
                {label: this.$translate('address', 'Adress'), value: this.contactInfo.addres},
                {label: this.$translate('county', 'Postort'), value: this.contactInfo.county},
                {label: this.$translate('postcode', 'Postnummer'), value: this.contactInfo.postalNumber},
                {label: this.$translate('phone', 'Telefon'), value: this.contactInfo.phone}, 
                {label: this.$translate('email', 'E-post'), value: this.contactInfo.email}, 
                {label: this.$translate('website', 'Webbplats'), value: this.contactInfo.website}
            ].filter(item => this.hasValue(item.value));
        },
    },
    methods: {
        hasValue(value) {
            return value && value !== '-';
        }
    }
}
</script>

<style lang="scss" scoped>
.contact-info-section{
    background-color: $lightgray;

    ul {
        @include spacing(margin,4,top);
        width: 100%;
        background-color: $white;
        @include spacing(padding,5);
        border-radius: 3px;
        box-shadow: $bxs;
        list-style: none;
        display: flex;
        flex-direction: column;

        li {
            width: 100%;
            @include flex(between,start);
            gap: 0.5rem;
            @include spacing(padding,3,(top,bottom));
            @include font-size(16px);
            @include border(bottom);

            &:first-child {
                @include spacing(padding,0,top);
            }
            &:last-child {
                @include spacing(padding,0,bottom);
                border-bottom: none;
            }
            .label {
                white-space: nowrap;
            }
            .value {
                font-weight: bold;
                text-align: right;
                word-break: break-all;
            }

            @include device(pad) {
                @include font-size(18px);
            }
        }
    }
}
</style>