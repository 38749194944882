<template>
    <div class="wrapper">
        <Cta :document="document" />

        <Banner :document="document" :model="model" />

        <JumpLinks :jump-links="jumpLinks" />

        <Features :document="document" />

        <!-- I dont lke this -->
        <Section
            v-if="document.data.good_for.length"
            id="goodfor"
            class="good-for"
            width="md"
        >
            <h2>{{ $translate('good_for', 'Bra för') }}</h2>
            <div 
                class="rich-text" 
                v-html="$prismic.asHtml(document.data.good_for)"
            />
        </Section>

        <Section
            id="info"
            width="lg"
            background="lgray"
            class="section-boxes"
        >
            <div class="container">
                <h3>{{ $translate('card_page_pros_header', 'Varför vi tycker om kortet') }}</h3>
                <ul>
                    <li
                        v-for="(iterator, index) in pros"
                        :key="index"
                    >
                        <i class="fas fa-check-circle" />
                        <div 
                            class="rich-text" 
                            v-html="$prismic.asHtml(iterator.pro)"
                        />
                    </li>
                </ul>
            </div>

            <div class="container">
                <h3>{{ $translate('card_page_cons_header', 'Varför vi tycker att kortet kunde vara bättre') }}</h3>
                <ul>
                    <li
                        v-for="(iterator, index) in cons"
                        :key="index"
                    >
                        <i class="fas fa-times-circle" />
                        <div 
                            class="rich-text" 
                            v-html="$prismic.asHtml(iterator.pro)"
                        />
                    </li>
                </ul>
            </div>

            <div class="container">
                <h3>{{ $translate('card_page_requirements_header', 'Krav') }}</h3>
                <ul>
                    <li
                        v-for="(requirement, index) in $getRequirements(model)"
                        :key="index"
                    >
                        <i class="fas fa-user-check" />
                        <span>{{ requirement }}</span>
                    </li>
                </ul>
            </div>

            <div class="container">
                <h3>{{ $translate('card_page_details_header', 'Fakta') }}</h3>

                <div class="container__list">
                    <!-- TODO what data to show, what data on what markets -->
                    <div>
                        <span>{{ $translate('card_type', 'Korttyp') }}</span>
                        <span v-if="model.isDebit">{{ $translate('prepaid_card', 'Prepaid') }}</span>
                        <span v-else-if="model.isAggregator">{{ $translate('aggregator_card', 'Prepaid') }}</span>
                        <span v-else>{{ $translate('credit_card', 'Kreditkort') }}</span> 
                    </div>
                    <div>
                        <span>{{ $translate('payment_network', 'Betalnätverk') }}</span>
                        <span>{{ paymentNetwork }}</span>
                    </div>
                    <div>
                        <span>{{ $translate('yearly_fee', 'Årsavgift') }}</span>
                        <span>
                            <span v-html="model.yearlyCostHtml" />
                            <first-year-offer-tooltip :model="model" />
                        </span>
                    </div>
                    <div>
                        <span>{{ $translate('age_limit', 'Åldersgräns') }}</span>
                        <span>{{ model.minAge }}</span>
                    </div>
                    <div>
                        <span>{{ $translate('rate', 'Ränta') }}</span>
                        <span>{{ model.interestString }}</span>
                    </div>
                    <div>
                        <span>{{ $translate('effective_rate', 'Effektiv ränta') }}</span>
                        <span>{{ model.effectiveInterestString }}</span>
                    </div>
                    <div v-if="!($isGermany())">
                        <span>{{ $translate('rate_free_days', 'Räntefria dagar') }}</span>
                        <span>{{ model.getInterestFreeDaysString($translate('core_models_rate_free_days', '{days} dagar')) }}</span>
                    </div>
                    <div v-if="!$isGermany()">
                        <span>{{ $translate('max_credit', 'Maxkredit') }}</span>
                        <span>{{ model.getMaxCreditString($translate('no_max_credit', 'Ingen kreditgräns')) }}</span>
                    </div>
                    <div v-if="$isSweden() || $isNorway()">
                        <span>{{ $translate('min_amount_to_pay', 'Minsta belopp att betala') }}</span>
                        <span>{{ model.getInvoiceMinToPayString() }}</span>
                    </div>
                    <div v-if="$isSweden() || $isNorway()">
                        <span>{{ $translate('invoice_fee', 'Aviavgift') }}</span>
                        <span>{{ model.getAviFeeString($translate('core_models_avi_fee', '{paperInvoiceFee} ({eInvoiceFee} e-faktura)')) }}</span>
                    </div>
                    <div>
                        <span>{{ $translate('currency_exchange_fee', 'Valutapåslag') }}</span>
                        <span>{{ model.getCurrencyExchangeFeeString() }}</span>
                    </div>
                    <div>
                        <span>{{ $translate('withdrawal_fee', 'Uttagsavgift') }}</span>
                        <span>{{ model.getWithdrawalFeeString() }}</span>
                    </div>
                    <div>
                        <span>{{ $translate('extra_card', 'Extrakort') }}</span>
                        <span>{{ $formatBoolean(model.hasFreeExtraCard) }}</span>
                    </div>
                </div>
            </div>
        </Section>

        <Section v-if="cardCalculatorEnabled" id="calculator" width="lg">
            <card-calculator
                :title="$translate('card_page_calculator_header', 'Räkna på {card}').replace('{card}', document.data.title)"
                :description="document.data.calculator_description"
                :pre-selected-card-id="document.id"
                :search-enabled="false"
            />
        </Section>

        <Section
            id="review"
            width="sm"
        >
            <div class="black width-full rich-text" v-html="$prismic.asHtml(document.data.deep_dive)" />
        </Section>

        <Section
            v-if="faq.length"
            id="faq"
            class="faq"
            itemscope itemtype="https://schema.org/FAQPage"
        >
            <div class="grid">
                <QuestionAndAnswer
                    v-for="(iterator, index) in faq"
                    :key="index"
                    :question="iterator.question"
                    :answer="iterator.answer"
                />
            </div>
        </Section>

        <OthersLike
            v-if="otherCards.length"
            id="other"
            :slice="otherCards"
            :header="document.data.cards_header"
        />

        <UserReview
            v-if="model._ratings"
            :title="document.data.title"
            :ratings="model._ratings"
            :brand-id="Number(model._core.core_id)"
            :inactive="document.data.archived"
        />
        
        <ContactInfo :model="model" />

        <script type="application/ld+json" v-html="jsonld" />
    </div>
</template>

<script>
import { capitalize } from 'lodash';
import FirstYearOfferTooltip from '@/components/cards/FirstYearOfferTooltip.vue';
import Banner from './CreditCardBanner.vue';
import Cta from './CreditCardCta.vue';
import Features from './CreditCardFeatures.vue';
import { QuestionAndAnswer } from '@swegaming-ab/vue-components';
import OthersLike from '@/components/slices/OthersLike.vue';
import UserReview from '@/components/UserReview.vue';
import CardCalculator from '@/components/card-calculator/index.vue';
import JumpLinks from '@/components/JumpLinks.vue';
import ContactInfo from '@/components/ContactInfo.vue';
export default {
    components: {
        Cta,
        Features,
        Banner,
        OthersLike,
        UserReview,
        QuestionAndAnswer,
        CardCalculator,
        FirstYearOfferTooltip,
        JumpLinks,
        ContactInfo
    },
    props: {
        document: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    data() {
        return {
            features: this.document.data.features.filter(this.$filterEmptyRelations).sort(this.sortFeatures),
            faq: this.document.data.faq.filter(this.$filterEmptyElements),
            pros: this.document.data.pros_condensed.filter(this.$filterEmptyElements),
            cons: this.document.data.cons_condensed.filter(this.$filterEmptyElements),
            otherCards: this.document.data.other_cards.filter(this.$filterEmptyRelations)
        };
    },
    computed: {
        jumpLinks() {
            return [
                {
                    title: this.$translate('features', 'Egenskaper'),
                    id: '#features',
                    icon: 'fas fa-list-check',
                    disabled: !this.features.length
                },
                {
                    title: this.$translate('good_for', 'Bra för dig'),
                    id: '#goodfor',
                    icon: 'fas fa-ballot-check',
                    disabled: !this.document.data.good_for.length
                },
                {
                    title: this.$translate('info', 'Info'),
                    id: '#info',
                    icon: 'far fa-circle-info',
                },
                {
                    title: this.$translate('calculator', 'Kalkylator'),
                    id: '#calculator',
                    icon: 'fas fa-calculator',
                    disabled: !this.cardCalculatorEnabled
                },
                {
                    title: this.$translate('review', 'Recension'),
                    id: '#review',
                    icon: 'fas fa-message-pen',
                },
                {
                    title: this.$translate('faq', 'FAQ'),
                    id: '#faq',
                    icon: 'fas fa-messages-question',
                    disabled: !this.faq.length
                },
                {
                    title: this.$translate('other_cards', 'Andra kort'),
                    id: '#other',
                    icon: 'fas fa-cards-blank',
                    disabled: !this.otherCards.length
                },
                {
                    title: this.$translate('user_reviews', 'Användarrecensioner'),
                    id: '#userreview',
                    icon: 'fas fa-users',
                    disabled: !this.model._ratings
                },
            ].filter(link => !link.disabled);
        },
        model() {
            return new this.$models.CreditCard(this.document);
        },
        jsonld() {

            if (!(this.model._ratings && 
                    this.model._ratings.data && 
                    this.model._ratings.data.length)) {
                return '';
            }

            let jsonld = {
                '@context': 'https://schema.org',
                '@type': 'Product',
                'name': this.document.data.title,
                'dateModified': this.$format.date(this.document.last_publication_date),
            };

            if (this.document.data.card_image && this.document.data.card_image.url) {
                jsonld.image = this.document.data.card_image.url;
            }

            if (this.model._ratings &&
                    this.model._ratings.data &&
                    this.model._ratings.data.length) {

                jsonld.aggregateRating = {
                    '@type': 'AggregateRating',
                    'ratingValue': this.model._ratings.average_score,
                    'ratingCount': this.model._ratings.count,
                    'reviewCount': this.model._ratings.count,
                    'worstRating': '1',
                    'bestRating': '5'
                };
            }

            return jsonld;
        },
        paymentNetwork() {
            return capitalize(this.model.paymentNetwork);
        },
        cardCalculatorEnabled() {
            return this.model.isCredit && !this.document.data.disable_calculator;
        }
    },
};
</script>

<style lang="scss" scoped>
    section.good-for {
        text-align: center;

        h2 {
            width: 100%;
            @include spacing(margin,8,bottom);
        }
    }
    .section.faq ::v-deep {
        .grid {
            @include grid(1, 15px);
        }
        .question-answer {
            background: $white;
            @include border-radius(5px);
            box-shadow: $bxs;
            color: $black;
            @include border-radius(3px);

            .question {
                @include spacing(padding, 5);
                font-family: $fontHeader;

                &-text {
                    font-family: $fontHeader;
                    color: $font;
                    @include font-size(20px);
                    font-weight: bold;
                    padding-right: 10px;
                }
            }

            .answer {
                @include spacing(padding, 5);
                @include border(top, 1px, $black);
                font-family: $fontText;
                @include font-size(16px, 10px);
            }
        }
    }

    .jump-links-wrapper::v-deep .link {
        font-size: 14px;
    }

    section.section-boxes::v-deep {
        background: $lightgray;
        margin: 40px 0;
        @include device(pad) {
            margin: 48px 0;
        }
        
        .section-inner {
            @include grid(1, 20px);

            @include device(pad) {
                @include grid(2, 20px);
            }

            .container {
                flex: 1;
                @include spacing(padding,5);
                box-shadow: $bxs;
                @include border-radius(3px);
                background: $white;

                h3 { @include spacing(margin, 6, bottom); }

                ul {
                    @include grid(1, 12px);
                    font-family: $fontText;


                    li {
                        @include flex(start,start);

                        i { 
                            align-self: flex-start;
                            margin-right: 10px;
                            line-height: 24px;
                        }

                        span,
                        div { 
                            flex: 1; 
                            line-height: 24px;
                        }

                        .rich-text {
                            p:first-child {
                                margin-top: 0;
                            }
                            p:last-child {
                                margin-bottom: 0;
                            }
                        }

                        strong { font-weight: bold; }

                        .fa-check-circle { color: $green; }
                        .fa-times-circle { color: $red; }
                        .fa-user-check { color: $pink; }
                    }
                }

                &__list {
                    @include grid(1, 0);

                    div {
                        @include spacing(padding,4, (top,bottom));
                        font-family: $fontText;
                        @include flex(between);
                        @include border(bottom);

                        @include device(desktop) {
                            @include spacing(padding,2, (top,bottom));
                        }

                        span {
                            width: 100%;
                            @include device(desktop) { width: auto; }

                            &:last-child {
                                font-weight: bold;
                                margin-top: 10px;
                                @include device(desktop) {
                                    margin-top: 0;
                                }
                            }
                        }

                        &:last-child { border: 0; }
                    }
                }
            }
        }
    }
</style>
