<template>
    <Section 
        v-if="jumpLinks.length" 
        :width="width" 
        class="jump-links-wrapper"
    >
        <ul class="jump-links">
            <li v-for="(jumpLink, index) in jumpLinks" :key="index">
                <a 
                    v-scroll-to="jumpLink.id" 
                    href="#"
                    class="link"
                >
                    <i 
                        v-if="jumpLink.icon"
                        :class="jumpLink.icon"    
                    />
                    {{ jumpLink.title }}
                </a>
            </li>
        </ul>
    </Section>
</template>
<script>
export default {
    props: {
        jumpLinks: {
            type: Array,
            required: true
        },
        width: {
            type: String,
            required: false,
            default: 'lg'
        }
    }
};
</script>
<style lang="scss" scoped>
    .jump-links-wrapper::v-deep {
        padding-top: 0;
        padding-bottom: 0;

        .jump-links {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: .5rem;
            @include device(pad) {
                flex-flow: row wrap;
            }

            li {
                @include flex;
                .link {
                    padding: 16px 24px;
                    font-family: $fontHeader;
                    @include border-radius(3px);
                    box-shadow: $bxs;
                    color: $font;
                    cursor: pointer;
                    transition: 0.3s ease-in-out;
                    flex-grow: 1;
                    text-align: center;
                    &:hover {
                        color: $white;
                        background-color: $pink;
                        i {
                            color: $white;
                        }
                    }
                    i {
                        margin-right: 0.25rem;
                        color: $pink;
                        transition: 0.3s ease-in-out;
                    }
                }
            }
        }
    }
</style>
