<template>
    <Section width="md" class="compare-data">
        <div 
            v-if="$validateText(headerText)"
            class="rich-text header" 
            v-html="$prismic.asHtml(headerText)"
        />
        <Table
            :table-values="tableValues"
            :out-links="cards"
            :enable-mobile-labels="true"
        >
            <th v-for="(card, index) in cards" :key="index" slot="table-headers">
                <nuxt-link :to="$prismic.linkResolver(card)">
                    <PrismicImage
                        :img="card.data.card_image"
                        w="200"
                    />
                </nuxt-link>
            </th>
        </Table>
    </Section>
</template>

<script>
import Table from './Table.vue';
import highlightHighestOrLowest from '@/mixins/highlight-highest-or-lowest.js';
export default {
    components: {
        Table
    },
    mixins: [
        highlightHighestOrLowest
    ],
    props: {
        text: {
            type: Array,
            required: false,
            default: Array
        },
        cards: {
            type: Array,
            required: true
        }
    },
    computed: {
        headerText() {
            return this.$validateText(this.text) 
                ? this.text 
                : this.$translate('compare_two_cards_compare_data_text');
        },
        tableValues() {
            return [
                { 
                    title: this.$translate('payment_network', 'Betalnätverk'),
                    dataItems: this.cards.map(card => {
                        return {
                            value: card.model.paymentNetwork
                        };
                    })
                },
                { 
                    title: this.$translate('yearly_fee', 'Årsavgift'),
                    dataItems: this.cards.map(card => {
                        return {
                            tooltipText: this.$translate('yearly_fee_tooltip'),
                            value: card.model.yearlyCostHtml,
                            validate: card.model.hasFirstYearOffer,
                            isHighlighted: this.checkLowest('yearlyCost', card)
                        };
                    }),
                    hasTooltip: true,
                },
                {
                    title: this.$translate('age_limit', 'Åldersgräns'),
                    dataItems: this.cards.map(card => {
                        return {
                            value: card.model.minAge || this.$translate('no_min_age_required', 'Ingen angiven åldersgräns'),
                            isHighlighted: this.checkLowest('minAge', card)
                        };
                    })
                },
                { 
                    title: this.$translate('rate', 'Ränta'),
                    dataItems: this.cards.map(card => {
                        return {
                            value: card.model.interestString,
                            isHighlighted: this.checkLowest('minInterestPossible', card)
                        };
                    })
                },
                { 
                    title: this.$translate('effective_rate', 'Effektiv ränta'),
                    dataItems: this.cards.map(card => {
                        return {
                            value: card.model.effectiveInterestString,
                            isHighlighted: this.checkLowest('minEffectiveInterestPossible', card) && card.model.isCredit
                        };
                    })
                },
                { 
                    title: this.$translate('rate_free_days', 'Räntefria dagar'),
                    dataItems: this.cards.map(card => {
                        return {
                            value: card.model.getInterestFreeDaysString(this.$translate('core_models_rate_free_days', '{days} dagar')),
                            isHighlighted: this.checkLowest('interestFreeDays', card)
                        };
                    }),
                    disabled: this.$isGermany() || this.$isFinland()
                },
                { 
                    title: this.$translate('max_credit', 'Maxkredit'),
                    dataItems: this.cards.map(card => {
                        return {
                            value: card.model.getMaxCreditString(this.$translate('no_max_credit', 'Ingen kreditgräns')),
                            isHighlighted: this.checkHighest('maxCredit', card)
                        };
                    }),
                    disabled: this.$isGermany()
                },
                { 
                    title: this.$translate('min_amount_to_pay', 'Minsta belopp att betala'),
                    dataItems: this.cards.map(card => {
                        return {
                            value: card.model.getInvoiceMinToPayString(),
                            isHighlighted: this.checkLowest('minAmountToPay', card)
                        };
                    }),
                    disabled: !(this.$isSweden() || this.$isNorway())
                },
                { 
                    title: this.$translate('invoice_fee', 'Aviavgift'),
                    dataItems: this.cards.map(card => {
                        return {
                            value: card.model.getAviFeeString(this.$translate('core_models_avi_fee', '{paperInvoiceFee} ({eInvoiceFee} e-faktura)')),
                            isHighlighted: this.checkLowest('minAviFee', card)
                        };
                    }),
                    disabled: !(this.$isSweden() || this.$isNorway())
                },
                { 
                    title: this.$translate('currency_exchange_fee', 'Valutapåslag'),
                    dataItems: this.cards.map(card => {
                        return {
                            value: card.model.getCurrencyExchangeFeeString(),
                            isHighlighted: this.checkLowest('currencyExchangeFeePercentage', card)
                        };
                    })
                },
                { 
                    title: this.$translate('withdrawal_fee', 'Uttagsavgift'),
                    dataItems: this.cards.map(card => {
                        return {
                            value: card.model.getWithdrawalFeeString()
                        };
                    })
                },
                { 
                    title: this.$translate('extra_card', 'Extrakort'),
                    dataItems: this.cards.map(card => {
                        return {
                            value: this.$formatBoolean(card.model.hasFreeExtraCard),
                            isHighlighted: card.model.hasFreeExtraCard
                        };
                    })
                },
                { 
                    title: this.$translate('mobile_payments', 'Mobila betalningsmetoder'),
                    dataItems: this.cards.map(card => {
                        return [
                            { title: 'Apple Pay', icon: this.$formatBooleanIcon(card.model.hasApplePay) },
                            { title: 'Samsung Pay', icon: this.$formatBooleanIcon(card.model.hasSamsungPay) },
                            { title: 'Google Pay', icon: this.$formatBooleanIcon(card.model.hasGooglePay) }
                        ];
                    }),
                    hasList: true,
                },
                { 
                    title: this.$translate('card_page_requirements_header', 'Krav'),
                    dataItems: this.cards.map(card => {
                        return this.$getRequirements(card.model).map(requirement => {
                            return {
                                title: requirement,
                                icon: 'fas fa-user-check'
                            };
                        });
                    }),
                    hasList: true
                },
                { 
                    title: this.$translate('compliance_example', 'Räkneexempel'),
                    dataItems: this.cards.map(card => {
                        return {
                            value: card.model.complianceExample
                        };
                    }),
                    disabled: !this.$isNorway() || !this.cards.some(card => card.model.complianceExample)
                }
            ].filter(data => !data.disabled);
        },
        lowestKeys() {
            return [
                'minEffectiveInterestPossible',
                'minAge',
                'minInterestPossible',
                'yearlyCost',
                'aviFeePaperInvoice',
                'aviFeeEInvoice',
                'currencyExchangeFeePercentage',
                'invoiceMinToPayPercentage',
                'invoiceMinToPaySum',
                'interestFreeDays',
            ];
        },
        highestKeys() {
            return [
                'maxCredit'
            ];
        },
        
    }
};
</script>
<style lang="scss" scoped>
    .compare-data::v-deep {
        .table-container .table {
            td, th {
                min-width: 200px !important;
                max-width: 250px !important;
            }
        }
    }
</style>