<template>
    <Section
        width="sm"
        :label="slice.slice_label"
    >
        <h3>{{ $prismic.asText(slice.primary.header) }}</h3>

        <div class="credit-card-info container">
            <div class="container-inner">
                <PrismicImage
                    :img="card.data.card_image"
                    w="250"
                />
                <ButtonTarget
                    v-if="card.data.redirect_enabled"
                    :link="card"
                    placement="credit-card-and-info"
                    :text="$translate('cta_credit_card_list', 'Till ansökan')"
                    icon="fas fa-lock white"
                    background="green"
                    size="md"
                />
                <span
                    v-if="card.data.redirect_enabled"
                    class="text-subtitle"
                >
                    {{ $getApplySecurelyString(card) }}
                </span>
            </div>

            <div class="container-inner">
                <Stars
                    v-if="model._ratings?.average_score"
                    :rating="model._ratings?.average_score"
                />
                <ButtonNuxt
                    :link="card"
                    :text="$translate('cta_read_more', 'Läs mer')"
                    icon="fas fa-arrow-right"
                    background="yellow"
                    size="sm"
                />

                <ListCardInfo :data="card" :model="model" class="narrow" />
            </div>
        </div>
        <span v-if="model.complianceExample" class="text-compliance">
            {{ model.complianceExample }}
        </span>

        <div
            class="rich-text"
            v-html="$prismic.asHtml(slice.primary.text)"
        />
    </Section>
</template>

<script>
import ListCardInfo from '@/components/slices/lists/ListCardInfo.vue';
import Stars from '@/components/stars/Stars.vue';
export default {
    components: { ListCardInfo, Stars },
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    computed: {
        card() {
            return this.$store.state.cards[this.slice.primary.credit_card.id];
        },
        model() {
            return new this.$models.CreditCard(this.card);
        },
    }
};
</script>

<style lang="scss" scoped>
.section {
    padding: 40px 20px;
}
section::v-deep {

    h3 {
        width: 100%;
        @include spacing(margin,4,bottom);
        text-align: center;

        @include device(pad) {
            text-align: left;
        }
    }

    .container {
        @include flex(center,start);

        &-inner {

            &:first-child {
                @include grid(1, 10px);
                text-align: center;
            }

            &:last-child {
                @include grid(1, 20px);
                flex: 1;
                padding-top: 20px;
                text-align: center;

                @include device(pad) {
                    padding-top: 0;
                    text-align: left;
                    padding-left: 40px;
                }

                .btn {
                    @include device(mobile) { margin: auto; }
                    max-width: 200px;
                }

                ul {
                    @include border(top);
                    padding-top: 10px;
                }
            }
        }
    }

    .rich-text {
        @include spacing(margin,6,top);
    }
}
</style>
