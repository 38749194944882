export default {
    computed: {
        tableHeaders() {
            return this.cards.map(card => card.data.title);
        },
        insurancesData() {
            return [
                {   
                    key: 'hasPurchaseProtection',
                    title: this.$translate('ct_purchase_protection', 'Köpskydd')
                },
                {
                    key: 'hasDeliveryInsurance',
                    title: this.$translate('ct_delivery_insurance', 'Leveransförsäkring')
                },
                {
                    key: 'hasTravelInsurance',
                    title: this.$translate('ct_travel_insurance', 'Reseförsäkring')
                },
                {
                    key: 'hasPriceGuarantee',
                    title: this.$translate('ct_price_guarantee', 'Prisgaranti')
                },
                {
                    key: 'hasComprehensiveInsurance',
                    title: this.$translate('ct_comprehensive_insurance', 'Allriskförsäkring')
                },
                {
                    key: 'hasCancellationProtection',
                    title: this.$translate('ct_cancellation_protection', 'Avbeställningsskydd')
                },
                {
                    key: 'hasDeductibleInsurance',
                    title: this.$translate('ct_deductible_insurance', 'Självriskreducering')
                },
                {
                    key: 'hasLostKeyInsurance',
                    title: this.$translate('ct_lost_key_insurance', 'Nyckelförsäkring')
                },
                {
                    key: 'hasWrongFuelInsurance',
                    title: this.$translate('ct_wrong_fuel_insurance', 'Feltankningsförsäkring')
                },
                {
                    key: 'hasIdTheftInsurance',
                    title: this.$translate('ct_id_theft_insurance', 'Id-skyddsförsäkring')
                },
                {   
                    key: 'hasCancelledEventInsurance',
                    title: this.$translate('ct_cancelled_event_insurance', 'Evenemangsförsäkring') 
                },
                {   
                    key: 'hasHoleInOneInsurance',
                    title: this.$translate('ct_hole_in_one_insurance', 'Hole-in-one försäkring') 
                },
            ];
        },
        tableValues() {
            return this.insurancesData.map(dataItem => ({
                title: dataItem.title,
                dataItems: this.cards.map(card => {
                    return {
                        value: 'fa-lg ' + this.$formatBooleanIcon(card.model[dataItem.key])
                    };
                }),
                hasIconValue: true
            }));
        }
    }
};