<template>
    <div class="compare-two-cards">
        <Banner 
            :slice="slice" 
            :cards="cards" 
        />
        <JumpLinks 
            :jump-links="jumpLinks"
        />
        <CompareData 
            id="compare-two-cards-compare-data"
            :text="slice.primary.compare_data_text"
            :cards="cards"
        />
        <Benefits 
            id="compare-two-cards-benefits"
            :text="slice.primary.benefits_text"
            :cards="cards"
        />
        <Insurances 
            id="compare-two-cards-insurances"
            :text="slice.primary.insurances_text"
            :cards="cards"
        />
        <CompiledData 
            id="compare-two-cards-data-compilation"
            :text="slice.primary.compiled_data_text"
            :cards="cards"
        />
        <UserRatings 
            id="compare-two-cards-reviews"
            :text="slice.primary.user_ratings_text" 
            :cards="cards"
        />
        <CompanyInfo 
            id="compare-two-cards-company-info"
            :cards="cards"
        />
    </div>
</template>
<script>
import Banner from './banner/index.vue';
import UserRatings from './user-ratings/index.vue';
import JumpLinks from '@/components/JumpLinks.vue';
import CompanyInfo from './company-info/index.vue';
import CompareData from './CompareData.vue';
import Benefits from './Benefits.vue';
import Insurances from './Insurances.vue';
import CompiledData from './CompiledData.vue';

export default {
    components: {
        Banner,
        UserRatings,
        JumpLinks,
        CompanyInfo,
        CompareData,
        Benefits,
        Insurances,
        CompiledData
    },
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    computed: {
        cardIds() {
            return [this.slice.primary.card_1.id, this.slice.primary.card_2.id];
        },
        cards() {
            return this.cardIds.map(id => {
                const card = this.$store.state.cards[id];
                const model = new this.$models.CreditCard(card);
                return {...card, model};
            });
        },
        jumpLinks() {
            return [
                {
                    id: '#compare-two-cards-compare-data',
                    title: this.$translate('compare_two_cards_jump_link_compare_data', 'Jämför data'),
                    icon: 'fas fa-scale-unbalanced-flip'
                },
                {
                    id: '#compare-two-cards-benefits',
                    title: this.$translate('compare_two_cards_jump_link_benefits', 'Förmåner'),
                    icon: 'fas fa-list-check'
                },
                {
                    id: '#compare-two-cards-insurances',
                    title: this.$translate('compare_two_cards_jump_link_insurances', 'Försäkringar'),
                    icon: 'fas fa-car-burst'
                },
                {
                    id: '#compare-two-cards-data-compilation',
                    title: this.$translate('compare_two_cards_jump_link_data_compilation', 'Sammanställning'),
                    icon: 'fas fa-clipboard-list'
                },
                {
                    id: '#compare-two-cards-reviews',
                    title: this.$translate('compare_two_cards_jump_link_reviews', 'Kundrecensioner'),
                    icon: 'fas fa-users'
                },
                {
                    id: '#compare-two-cards-company-info',
                    title: this.$translate('compare_two_cards_jump_link_company_info', 'Kontakt & Öppettider'),
                    icon: 'fas fa-building-user'
                },
            ];
        }
    }
};
</script>
<style lang="scss" scoped>
    .compare-two-cards::v-deep {
        .header {
            margin-bottom: 1.5rem;
        }
    }
</style>
