<template>
    <Section width="md">
        <div class="container">
            <div 
                v-if="headerText"
                class="rich-text header" 
                v-html="$prismic.asHtml(headerText)" 
            />
            <div class="reviews-wrapper">
                <Review 
                    v-for="(card, index) in cards" 
                    :key="index"
                    :card-title="card.data.title"
                    :ratings="card.model._ratings" 
                />
            </div>
        </div>
    </Section>
</template>
<script>
export default {
    props: {
        text: {
            type: Array,
            required: false,
            default: Array
        },
        cards: {
            type: Array,
            required: true
        }
    },
    computed: {
        headerText() {
            return this.$validateText(this.text) 
                ? this.text
                : this.$translate('compare_two_cards_user_ratings_text');
        }
    }
};
</script>
<style lang="scss" scoped>
    .container::v-deep {
        .reviews-wrapper {
            display: grid;
            grid-template-columns: 1fr;
            gap: 2rem;
            align-items: flex-start;
    
            @include device(pad) {
                gap: 3rem;
                grid-template-columns: 1fr 1fr;
            }

            .review {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 1.5rem;
                box-shadow: $bxs;
                @include border-radius(3px);
                padding: 1rem;

                .review-header {
                    display: flex;
                    flex-direction: column;
                    gap: 0.5rem;
                    width: 100%;
                    @include border(bottom);
                    font-family: $fontText;
                    padding-bottom: 1rem;

                    .stars-with-count {
                        display: flex;
                        align-items: baseline;
                        gap: 0.5rem;
                        
                        .width-full {
                            width: fit-content;
                        }
                        span {
                            font-style: italic;
                        }
                    }
                }

                .ratings {
                    display: flex;
                    flex-direction: column;	
                    gap: 1rem;
                    width: 100%;
                    font-family: $fontText;

                    .rating {
                        display: flex;
                        flex-direction: column;
                        gap: 0.75rem;    
                        &:not(:last-child) {
                            padding-bottom: 1rem;
                            @include border(bottom);
                        }

                        .rating-header {
                            display: flex;
                            justify-content: space-between;
                            flex-wrap: wrap;
                            gap: 0.5rem;

                            .rater-name {
                                font-family: $fontHeader;
                                font-size: 18px;
                                font-weight: bold;
                            }
                            .rating-score {
                                width: fit-content;
                            }
                        }
                    }
                }
            }
        }
    }
</style>