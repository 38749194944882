<template>
    <div class="review">
        <div class="review-header">
            <h3>
                {{ cardReviewHeader }}
            </h3>
            <StarsWithCount 
                :show-count="true" 
                :data="ratings" 
                class="stars-with-count"
                :star-class="'fa-lg'"
            />
        </div>
        <div v-if="ratings.data.length" class="ratings">
            <div 
                v-for="rating in ratingsData" 
                :key="rating.id"
                class="rating"
            >
                <div class="rating-header">
                    <span class="rater-name">
                        {{ rating.rater_name }}
                    </span>
                    <Stars
                        class="rating-score"
                        :rating="rating.rating_score"
                        :star-class="'fa-md'"
                    />
                </div>
                <div v-html="$format.comment(rating.rating_body)" />
            </div>
        </div>
        <div v-if="!ratings.data.length">
            <p>
                {{ $translate('no_reviews', 'Finns inga recensioner') }}
            </p>
        </div>
    </div>
</template>
<script>
import Stars from '@/components/stars/Stars.vue';
import StarsWithCount from '@/components/stars/StarsWithCount.vue';
import { orderBy } from 'lodash';
export default {
    components: { 
        Stars, 
        StarsWithCount 
    },
    props: {
        ratings: {
            type: Object,
            required: true
        },
        cardTitle: {
            type: String,
            required: true
        }
    },
    computed: {
        cardReviewHeader() {
            return this.$translate('card_reviews', '{card} recensioner')
                .replace('{card}', this.cardTitle);
        },
        ratingsData() {
            return orderBy(this.ratings.data, 'posted_at', 'desc')
                .slice(0, 3);
        }
    }
};
</script>