<template>
    <Section 
        width="md"
        class="table-section"    
    >
        <div 
            v-if="$validateText(slice.primary.h2)" 
            class="header" 
            v-html="$prismic.asHtml(slice.primary.h2)" 
        />
        <div 
            v-if="$validateText(slice.primary.p)" 
            class="rich-text"
            v-html="$prismic.asHtml(slice.primary.p)" 
        />
        <div class="table-card mobile-scroll-shadow">
            <table class="table">
                <thead>
                    <tr class="table-header">
                        <th v-for="(title, index) in tableTitles" :key="index">
                            {{ title }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(values, index) in tableValues" :key="index">
                        <td v-for="(value, valueIndex) in values" :key="valueIndex">
                            <div 
                                class="rich-text table-td" 
                                v-html="value" 
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div 
            v-if="$validateText(slice.primary.footer_text)"
            class="rich-text footer-text"
            v-html="$prismic.asHtml(slice.primary.footer_text)"
        />
    </Section>
</template>
<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    computed: {
        tableTitles() {
            return Object.entries(this.slice.primary)
                .filter(([key, value]) => key.startsWith('title_') && value)
                .map(([, value]) => value);
        },
        tableValues() {
            return this.slice.items
                .map(values => {
                    return Object.entries(values)
                        .filter(([key]) => key.startsWith('data_'))
                        .slice(0, this.tableTitles.length)
                        .map(([, value]) => {
                            if (!this.$validateText(value)) {
                                return '-';
                            }
                            if (typeof value === 'object') {
                                return this.$removeParagraphs(this.$prismic.asHtml(value));
                            }
                            return value;
                        });
                });
        }
    },
};
</script>
<style lang="scss" scoped>
    .table-section ::v-deep {
        .header {
            margin-bottom: 1rem;
        }
        
        .table-card {
            margin-top: 3rem;
            @include border-radius(15px);
            @include border(all,1px,rgba(211, 211, 211, 0.3));
            box-shadow: $bxs;
            overflow: auto;

            .table {
                width: 100%;
                tr {
                    @include border(bottom,1px,rgba(211, 211, 211, 0.3));
                    &:last-child { border: 0; }
                    &:nth-child(odd) {
                        background: hsla(0,0%,96.9%,.9);
                    }
                }
                tr.table-header {
                    background: unset;
                    @include border(bottom,1px,rgba(211, 211, 211, 0.3));
                    th {
                        font-family: $fontHeader;
                        text-align: left;
                        vertical-align: middle;
                        @include font-size(16px,8px);
                    }
                }
                td, th {
                    min-width: 150px;
                    padding: 25px;
                    font-family: $fontText;
                    vertical-align: top;
                    @include font-size(14px,8px);
                    @include border(right,1px,rgba(211, 211, 211, 0.3));
                    &:last-child { border: 0; }
                }
                .table-td * {
                    @include font-size(14px,8px);
                }
            }
        }
        .footer-text {
            margin-top: 2rem;

            & * {
                @include font-size(14px,8px);
            }
        }
    };
</style>