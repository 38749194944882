<template>
    <div class="card">
        <div class="card-image-rating">
            <nuxt-link :to="$prismic.linkResolver(card)" class="card-link">
                <PrismicImage
                    :img="card.data.card_image"
                    w="146"
                />
                <span class="card-name">
                    {{ card.data.title }}
                </span>
            </nuxt-link>
            <Stars 
                :rating="card.model._ratings.average_score" 
                :star-class="'fa-md'"  
                class="card-rating"  
            />
        </div>
        <div class="content-wrapper">
            <ul class="card-info">
                <li 
                    v-for="(data, index) in cardInfo" 
                    :key="index"
                    class="card-info-data"
                >
                    <span class="label">
                        {{ data.title }}
                    </span>
                    <div v-if="data.hasFirstYearOffer" class="value">
                        <span v-html="data.value" />
                        <firstYearOfferTooltip :model="card.model" />
                    </div>
                    <span v-else class="value">
                        {{ data.value }}
                    </span>
                </li>
            </ul>
            <div class="card-out-link-wrapper">
                <ButtonTarget
                    v-if="card.data.redirect_enabled"
                    :link="card"
                    :text="$translate('cta_credit_card_list', 'Till ansökan')"
                    icon="fas fa-lock white"
                    background="green"
                    size="sm"
                    class="card-out-link"
                />
                <div v-if="$isNorway() && card.data.redirect_enabled" class="text-subtitle">
                    <span>{{ $getApplySecurelyString(card) }}</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Stars from '@/components/stars/Stars.vue';
import FirstYearOfferTooltip from '@/components/cards/FirstYearOfferTooltip.vue';
export default {
    components: { 
        FirstYearOfferTooltip,
        Stars
    },
    props: {
        card: {
            type: Object,
            required: true
        }
    },
    computed: {
        cardInfo() {
            return [
                {
                    title: this.$translate('max_credit', 'Maxkredit'),
                    value: this.card.model.getMaxCreditString(this.$translate('no_max_credit', 'Ingen kreditgräns'))
                },
                {
                    title: this.$translate('yearly_fee', 'Årsavgift'),
                    value: this.card.model.yearlyCostHtml,
                    hasFirstYearOffer: this.card.model.hasFirstYearOffer,
                },
                {
                    title: this.$translate('effective_rate', 'Effektiv ränta'),
                    value: this.card.model.effectiveInterestString
                },
            ];
        }
    }
};
</script>