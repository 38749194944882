<template>
    <!--NOTE: UGLY fix to exclude Klarna Card in Sweden
        on desktop. -->
    <li :class="id === 'X5wHEhIAACIAO6jy' ? 'hide-klarna' : ''">
        <div class="credit-card">
            <div class="credit-card-header-wrapper">
                <div v-if="$validateText(highlightedText)" class="highlighted-text">
                    {{ highlightedText }}
                </div>
                <div class="credit-card-inner credit-card-header">
                    <div class="inner-container">
                        <nuxt-link :to="$prismic.linkResolver(card)">
                            <PrismicImage
                                :img="card.data.card_image"
                                w="250"
                                h="159"
                            />
                        </nuxt-link>

                        <ButtonTarget
                            v-if="card.data.redirect_enabled"
                            :link="card"
                            :placement="placement"
                            :position="position"
                            :text="$translate('cta_credit_card_list', 'Till ansökan')"
                            background="green"
                            size="md"
                            icon="fas fa-lock white"
                        />

                        <span
                            v-if="card.data.redirect_enabled"
                            class="text-subtitle"
                        >
                            {{ $getApplySecurelyString(card) }}
                        </span>
                    </div>
                </div>
            </div>

            <div class="credit-card-inner credit-card-body">
                <div class="container header">
                    <div class="header-inner">
                        <h3>{{ card.data.title }}</h3>

                        <CardStarsWithCount
                            v-if="model._ratings.count"
                            :data="model._ratings"
                            :show-count="false"
                        />
                    </div>

                    <div
                        class="header-inner"
                        @click="toggleCompare(id);"
                    >
                        <span class="text-highlight">{{ $translate('add_card_to_comparision') || 'Jämför kort' }}</span>
                        <div class="checkbox">
                            <i
                                v-if="compare"
                                class="fas fa-check"
                            />
                        </div>
                    </div>
                </div>

                <div class="container card-info">
                    <ListCardInfo 
                        :data="card" 
                        :model="model" 
                        :template="listTemplate"
                    />
                </div>

                <ul class="container options">
                    <li
                        v-for="(option, index) in infoOptions"
                        :key="`option${index}`"
                        :class="{lined: active === option.value}"
                        class="text-highlight"
                        @click="active = option.value"
                    >
                        {{ option.text }}
                    </li>
                </ul>

                <ul
                    v-if="active === 'pros'"
                    class="container list"
                >
                    <li
                        v-for="(pro, index) in pros"
                        :key="`pro${index}`"
                        class="list-icon list-check"
                        v-html="$removeParagraphs($prismic.asHtml(pro))"
                    />
                </ul>

                <div
                    v-if="active === 'details'"
                >
                    <div v-for="(dataCategory, categoryIndex) in detailsData()" :key="categoryIndex">
                        <span v-if="$validateText(dataCategory.header)" class="header">{{ dataCategory.header }}</span>
                        <ul class="container list details">
                            <li v-for="(item, index) in dataCategory.data" :key="index">
                                <span>
                                    {{ item.title }}
                                    <tooltip v-if="$validateText(item.tooltipText)" :tooltip-text="item.tooltipText" />
                                </span>
                                <span v-if="item.isFirstYearOffer">
                                    <span v-html="item.value" />
                                    <first-year-offer-tooltip :model="model" />
                                </span>
                                <i v-else-if="item.iconClass" class="fas" :class="item.iconClass" />
                                <span v-else>{{ item.value }}</span>
                            </li>
                        </ul>
                    </div>
                </div>



                <ul
                    v-if="active === 'requirements'"
                    class="container list"
                >
                    <li
                        v-for="(requirement, index) in $getRequirements(model)"
                        :key="`demand${index}`"
                        class="list-icon list-demand"
                    >
                        {{ requirement }}
                    </li>
                </ul>

                <div
                    v-if="active === 'review'"
                    class="container review"
                >
                    <p>{{ $prismic.asText(card.data.good_for) }}</p>

                    <ButtonNuxt
                        :link="card"
                        background="pink"
                        size="sm"
                        :text="$translate('card_list_to_card_page', 'Läs hela recensionen')"
                    />
                </div>
            </div>

            <div
                v-if="card.data.redirect_enabled"
                class="credit-card-inner credit-card-footer"
            >
                <ButtonTarget
                    :link="card"
                    :placement="placement"
                    :position="position"
                    :text="$translate('cta_credit_card_list', 'Till ansökan')"
                    background="green"
                    size="md"
                    class="flex"
                    icon="fas fa-lock white"
                />
                <span class="text-subtitle">{{ $getApplySecurelyString(card) }}</span>
            </div>
        </div>

        <span
            v-if="$isNorway()"
            class="text-compliance"
        >
            {{ model.complianceExample }}
        </span>
        <credit-warning v-if="showCreditWarning" />
    </li>
</template>
<script>
import FirstYearOfferTooltip from '@/components/cards/FirstYearOfferTooltip.vue';
import Tooltip from '@/components/Tooltip.vue';
import { includes } from 'lodash';
import CardStarsWithCount from '@/components/stars/StarsWithCount.vue';
import ListCardInfo from '@/components/slices/lists/ListCardInfo.vue';
import { pick } from 'lodash';
import CreditWarning from '@/components/CreditWarning.vue';
export default {
    components: { CardStarsWithCount, ListCardInfo, FirstYearOfferTooltip, Tooltip, CreditWarning },
    props: {
        id: {
            type: String,
            required: true,
            default: String
        },
        placement: {
            type: String,
            required: false,
            default: null
        },
        position: {
            type: Number,
            required: false,
            default: null
        },
        slice: {
            type: Object,
            required: false,
            default: null
        },
        highlightedText: {
            type: String,
            required: false,
            default: null
        }
    },
    data() {
        return {
            active: 'pros',
        };
    },
    computed: {
        listTemplate() {
            if (!this.slice) {
                return 'default';
            }

            return this.slice.primary.list_template || 'default';
        },
        isBonusList() {
            return this.listTemplate === 'bonus';
        },
        isTravelList() {
            return this.listTemplate === 'travel';
        },
        isFuelList() {
            return this.listTemplate === 'fuel';
        },
        isShoppingList() {
            return this.listTemplate === 'shopping';
        },
        card() {
            return this.$store.state.cards[this.id];
        },
        model() {
            return new this.$models.CreditCard(this.card);
        },
        compare() {
            return includes(this.$store.state.cardsToCompare, this.id);
        },
        showCreditWarning() {
            return this.$isSweden() && this.model.isCredit;
        },
        pros() {
            if (this.isBonusList) {
                const bonusPros = (this.card.data.bonus_list_pros || [])
                    .map(iterator => iterator.pro)
                    .filter(pro => pro && this.$prismic.asText(pro)?.trim().length);

                if (bonusPros.length > 0) {
                    return bonusPros;
                }
            }
            else if (this.isTravelList) {
                const travelPros = (this.card.data.travel_list_pros || [])
                    .map(iterator => iterator.pro)
                    .filter(pro => pro && this.$prismic.asText(pro)?.trim().length);

                if (travelPros.length > 0) {
                    return travelPros;
                }
            }
            else if (this.isFuelList) {
                const fuelPros = (this.card.data.fuel_list_pros || [])
                    .map(iterator => iterator.pro)
                    .filter(pro => pro && this.$prismic.asText(pro)?.trim().length);

                if (fuelPros.length > 0) {
                    return fuelPros;
                }
            }
            else if (this.isShoppingList) {
                const shoppingPros = (this.card.data.shopping_list_pros || [])
                    .map(iterator => iterator.pro)
                    .filter(pro => pro && this.$prismic.asText(pro)?.trim().length);

                if (shoppingPros.length > 0) {
                    return shoppingPros;
                }
            }

            return this.card.data.pros
                .map(iterator => iterator.pro)
                .filter(pro => pro && this.$prismic.asText(pro)?.trim().length);
        },
        prosHeader() {
            if (this.slice && this.slice.primary.pros_header) {
                return this.slice.primary.pros_header;
            }
            else if (this.isTravelList) {
                return this.$translate('card_list_travel_pros_header', 'Resefördelar');
            }
            else if (this.isBonusList) {
                return this.$translate('card_list_bonus_pros_header', 'Bonus & fördelar');
            }
            else if (this.isFuelList) {
                return this.$translate('card_list_fuel_pros_header', 'Bensinrabatter');
            }
            else if (this.isShoppingList) {
                return this.$translate('card_list_shopping_pros_header', 'Shoppingfördelar');
            }

            return this.$translate('card_list_pros_header', 'Fördelar');
        },
        infoOptions() {
            return [
                {
                    value: 'pros',
                    text: this.prosHeader
                },
                {
                    value: 'details',
                    text: this.$translate('card_list_details_header', 'Detaljer')
                },
                {
                    value: 'requirements',
                    text: this.$translate('card_list_requirements_header', 'Krav')
                },
                {
                    value: 'review',
                    text: this.$translate('card_list_short_review_header', 'Recension')
                }
            ];
        },
        allCardData() {
            return {
                yearlyFeeYear1: {
                    title: this.$translate('yearly_fee_year_1'),
                    value: this.$format.currency(this.model.firstYearCost) || this.model.yearlyCostString,
                    tooltipText: this.$translate('tooltip_yearly_fee_year_1')
                },
                yearlyFeeFromYear2: {
                    title: this.$translate('yearly_fee_from_year_2'),
                    value: this.model.yearlyCostString,
                    tooltipText: this.$translate('tooltip_yearly_fee_from_year_2')
                },
                interest: {
                    title: this.$translate('rate', 'Ränta'),
                    value: this.model.interestString,
                    tooltipText: this.$translate('tooltip_interest_rate')
                },
                effectiveInterest: {
                    title: this.$translate('effective_rate', 'Effektiv ränta'),
                    value: this.model.effectiveInterestString,
                    tooltipText: this.$translate('tooltip_effective_interest_rate')
                },
                paymentCostsInland: {
                    title: 'Inland',
                    value: this.$format.percentage(0, 0),
                    tooltipText: this.$translate('tooltip_payment_costs_inland')
                },
                paymentCostsEurozone: {
                    title: 'Eurozone',
                    value: this.$format.percentage(0, 0),
                    tooltipText: this.$translate('tooltip_payment_costs_eurozone')
                },
                paymentCostsWorldwide: {
                    title: 'Fremdwährung weltweit',
                    value: this.model.getCurrencyExchangeFeeString(),
                    tooltipText: this.$translate('tooltip_payment_costs_worldwide')
                },
                withdrawalCostsInland: {
                    title: 'Inland',
                    value: this.model.getWithdrawalFeeString(),
                    tooltipText: this.$translate('tooltip_withdrawal_costs_inland')
                },
                withdrawalCostsEurozone: {
                    title: 'Eurozone',
                    value: this.model.getWithdrawalFeeString(),
                    tooltipText: this.$translate('tooltip_withdrawal_costs_eurozone')
                },
                withdrawalCostsWorldwide: {
                    title: 'Fremdwährung weltweit',
                    value: (this.model.withdrawalFeePercentage === 0 && this.model.withdrawalFeeFixed === 0) ?
                        this.model.getCurrencyExchangeFeeString() : 
                        this.model.getWithdrawalFeeString(),
                    tooltipText: this.$translate('tooltip_withdrawal_costs_worldwide')
                },
                yearlyFee: {
                    title: this.$translate('yearly_fee', 'Årsavgift'),
                    value: this.model.yearlyCostHtml,
                    isFirstYearOffer: true,
                    tooltipText: this.$translate('tooltip_yearly_fee')

                },
                interestFreeDays: {
                    title: this.$translate('rate_free_days', 'Räntefria dagar'),
                    value: this.model.getInterestFreeDaysString(this.$translate('core_models_rate_free_days', '{days} dagar')),
                    tooltipText: this.$translate('tooltip_interest_free_days')
                },
                maxCredit: {
                    title: this.$translate('max_credit', 'Maxkredit'),
                    value: this.model.getMaxCreditString(this.$translate('no_max_credit', 'Ingen kreditgräns')),
                    tooltipText: this.$translate('tooltip_max_credit')
                },
                currencyExchangeFee: {
                    title: this.$translate('currency_exchange_fee', 'Valutapåslag'),
                    value: this.model.getCurrencyExchangeFeeString(),
                    tooltipText: this.$translate('tooltip_currency_exchange_fee')
                },
                withdrawalFee: {
                    title: this.$translate('withdrawal_fee', 'Uttagsavgift'),
                    value: this.model.getWithdrawalFeeString(),
                    tooltipText: this.$translate('tooltip_withdrawal_fee')
                },
                invoiceFee: {
                    title: this.$translate('invoice_fee', 'Aviavgift'),
                    value: this.model.getAviFeeString(this.$translate('core_models_avi_fee', '{paperInvoiceFee} ({eInvoiceFee} e-faktura)')),
                    tooltipText: this.$translate('tooltip_invoice_fee')
                },
                hasFreeExtraCard: {
                    title: this.$translate('extra_card', 'Gratis extrakort'),
                    value: this.$formatBoolean(this.model.hasFreeExtraCard),
                    tooltipText: this.$translate('tooltip_extra_card')
                },
                applePay: { 
                    title: 'Apple Pay',
                    iconClass: this.$formatBooleanIcon(this.model.hasApplePay),
                    tooltipText: this.$translate('tooltip_apple_pay')
                },
                googlePay: {
                    title: 'Google Pay',
                    iconClass: this.$formatBooleanIcon(this.model.hasGooglePay),
                    tooltipText: this.$translate('tooltip_google_pay')
                },
                samsungPay: {
                    title: 'Samsung Pay',
                    iconClass: this.$formatBooleanIcon(this.model.hasSamsungPay),
                    tooltipText: this.$translate('tooltip_samsung_pay')
                }
            };
        }
    },
    methods: {
        toggleCompare: function(cardId) {
            if (this.compare) this.$store.commit('REMOVE_CARD_FROM_COMPARE', cardId);
            else this.$store.commit('ADD_CARD_TO_COMPARE', cardId);
        },
        getDataForDetailsTab(keys) {
            return pick(this.allCardData, keys);
        },
        getGeneralDataKeys() {
            let keys = [];
            if (this.$isGermany()) {
                keys.push('yearlyFeeYear1', 'yearlyFeeFromYear2', 'hasFreeExtraCard');
                if (this.model.isCredit) keys.push('maxCredit', 'interest', 'effectiveInterest');
            }
            else {
                keys.push('yearlyFee', 'interest', 'effectiveInterest', 'interestFreeDays', 'maxCredit', 'currencyExchangeFee', 'withdrawalFee', 'hasFreeExtraCard');
                if (this.$isSweden() || this.$isNorway()) keys.push('invoiceFee');
            }
            
            return keys;
            
        },
        detailsData() {
            const detailsDataStructure = {
                general: { 
                    data: this.getDataForDetailsTab(this.getGeneralDataKeys())
                },
                paymentCosts: this.getPaymentCostsData(),
                withdrawalCosts: this.getWithdrawalCostsData(),
                mobilePayments: this.getMobilePaymentsData(),
            };
            return Object.values(detailsDataStructure).filter(item => item !== null);
        },
        getPaymentCostsData() {
            if (this.$isGermany()) {
                return {
                    data: this.getDataForDetailsTab(['paymentCostsInland', 'paymentCostsEurozone', 'paymentCostsWorldwide']),
                    header: this.$translate('card_payment_costs')
                };
            }
            return null;
        },

        getWithdrawalCostsData() {
            if (this.$isGermany()) {
                return {
                    data: this.getDataForDetailsTab(['withdrawalCostsInland', 'withdrawalCostsEurozone', 'withdrawalCostsWorldwide']),
                    header: this.$translate('card_withdrawal_fees')
                };
            }
            return null;
        },

        getMobilePaymentsData() {
            if (!this.$isFinland()) {
                return {
                    data: this.getDataForDetailsTab(['applePay', 'googlePay', 'samsungPay']),
                    header: this.$translate('mobile_payments')
                };
            }
            return null;
        },
    }
};
</script>
<style lang="scss" scoped>

.hide-klarna {
    @media screen and (min-width: 768px) {
        display: none;
    }
}

.credit-card.show {
    animation: show .3s ease-in forwards;
}
@keyframes show {
    from {
        opacity: 0;
        transform: translateX(50px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}
    .credit-card {
        @include border-radius(5px);
        box-shadow: $bxs;
        @include flex(center, start);
        @include flex(center, stretch);
        overflow: hidden;
        .credit-card-header-wrapper {
            width: 100%;
            flex: 1;
            @include device(pad) {
                max-width: 330px;
                width: auto;
            }
            display: flex;
            flex-direction: column;
            align-items: center;
            .highlighted-text {
                font-family: $fontText;
                width: 100%;
                @include spacing(padding, 2);
                background: $pink;
                color: $white;
                text-align: center;
                font-size: 20px;
            }
        }

        &-inner {
            width: 100%;
            @include flex(center,start);
            @include spacing(padding, 5);

            @include device(pad) {
                width: auto;
            }

            .inner-container {
                @include flex;
                @include grid(1, 10px);

            }

            .container { width: 100%; }

            .lined { border-bottom: 3px solid $pink; }
        }

        &-inner.credit-card-header {
            text-align: center;
            max-width: 320px;

            @include device(pad) {
                flex: 1;
                max-width: 330px;
            }

            img { height: auto; }
        }

        &-inner.credit-card-body {
            @include grid(1, 25px);
            background: $lightblue;
            @include device(pad) { flex: 2; }

            .container.header {
                @include grid(1, 20px);

                @include device(pad) {
                    @include flex(between,start);
                }

                .header-inner {
                    text-align: center;

                    @include device(pad) {
                        text-align: left;
                    }

                    &:first-child {
                        @include grid(1, 10px);
                    }

                    &:last-child {
                        cursor: pointer;
                        @include flex;

                        @include device(pad) {
                            @include flex(between);
                        }
                    }

                    span {
                        display: inline;
                        @include spacing(margin,3,right);
                    }

                    .checkbox {
                        @include flex;
                        @include size(20px);
                        border: 2px solid $pink;
                        border-radius: 3px;

                        i {
                            @include font-size(20px, 0px);
                            color: $green;
                        }
                    }
                }
            }

            .container.options {
                @include flex(start);
                gap: 1rem;
                @include device(pad) {
                    gap: 1.25rem;
                }
                li {
                    @include font-size(16px);
                    cursor: pointer;

                    @include device(pad) {
                        @include font-size(18px);
                    }
                }
            }

            .container.list {
                @include grid(1, 10px);

                li {
                    font-family: $fontText;
                    @include font-size(14px);
                }
            }

            .list.details {
                gap: 0;

                li {
                    @include flex(between);
                    @include spacing(padding, 2);

                    span {
                        &:last-child {
                            font-weight: bold;
                            font-family: $fontHeader;
                            text-align: right;

                            @media screen and (max-width: 400px) {
                                width: 100%;
                                text-align: left;
                                padding-top: 10px;
                            }

                            @include device(pad) {
                                flex: 1;
                                width: auto;
                            }
                        }
                    }

                    i.fa-check-circle,
                    i.fa-circle-check {
                        color: $green;
                    }
                    i.fa-times-circle {
                        color: $red;
                    }
                }

                li:nth-child(odd) { background: $white; }
            }

            span.header {
                font-family: $fontText;
                @include spacing(padding, 2);
                @include font-size(14px);
                display: block;
                margin-top: 15px;
                font-weight: bold;
            }

            .container.review {
                @include flex(start,start);
                width: 100%;

                p {
                    @include font-size(14px);
                }

                .btn { @include spacing(margin, 6, top);}
            }
        }

        &-inner.credit-card-footer {
            @include grid(1, 10px);
            text-align: center;

            @include device(pad) {
                display: none;
            }
        }
    }


    
</style>
